<template>
  <div>
    <div class="overlay" v-if="loader">
      <b-spinner class="loader" variant="primary" label="Spinning"></b-spinner>
    </div>
    <b-navbar toggleable="lg" type="light" variant="light" fixed="top">
      <b-img
        @click="homeIconClikced()"
        v-bind="logoProps"
        :src="require('@/assets/imgs/Vatika_logo.png')"
        fluid
        alt="Vatika image"
        style="cursor: pointer"
      ></b-img>
      <b-navbar-brand href="#" v-if="activeMenu">
        <button class="btn btn-vatika text-start">
          {{ activeMenu }}
        </button>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto flex nav-right">
          <a v-if="showUserManualLink" target="_blank" class="">
            <b-button
              pill
              variant="info"
              style="margin-right: 20px; margin-top: 10px; padding: 0.3rem 1rem !important;font-weight:600"
              title="Download User Manual"
              :href="downloadGstUserManualUrl + '/' + userManualGstFile"
              >User Manual</b-button
            >
          </a>
          <span class="user-name" v-if="userName">{{
            userName && `Welcome - ${userName}`
          }}</span>

          <!-- <b-nav-item-dropdown right v-if="leList"> -->
          <!-- Using 'button-content' slot -->
          <!-- <template #button-content>
              <b class="btn btn-vatika text-start">{{
                leList[0].org_short_name
              }}</b>
            </template>
            <b-dropdown-item
              href="#"
              v-for="(item, index) in leList"
              :key="index"
              >{{ item.org_short_name }}</b-dropdown-item
            >
          </b-nav-item-dropdown> -->

          <b-row>
            <b-col md="12">
              <b-row class="text-center" style="vertical-align: middle">
                <b-col
                  class="btn-icons"
                  md="7"
                  style="padding-top: 15px"
                  v-if="userName"
                >
                  <b-row>
                    <b-col class="p-1" style="cursor: pointer">
                      <div>
                        <b-icon
                          icon="lock-fill"
                          aria-hidden="true"
                          :title="'Change Password'"
                          @click="showChangePasswordForm()"
                        ></b-icon>
                      </div>
                    </b-col>
                    <b-col class="p-1" style="cursor: pointer">
                      <div>
                        <b-icon
                          icon="bell"
                          aria-hidden="true"
                          @click="showNotificationSummary"
                          :title="'Notifications'"
                        ></b-icon>
                        <span
                          class="noti-counter"
                          @click="showNotificationSummary"
                          >{{ notificationCount }}</span
                        >
                      </div>
                    </b-col>
                    <b-col class="p-1" style="cursor: pointer">
                      <div>
                        <b-icon
                          icon="envelope"
                          aria-hidden="true"
                          :title="'Mail'"
                        ></b-icon>
                      </div>
                    </b-col>
                    <b-col class="p-1" style="cursor: pointer">
                      <div>
                        <font-awesome-icon
                          :title="'Log Out'"
                          icon="sign-out-alt"
                          @click="singout"
                        />
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col style="align-self: center" class="p-0 login-section">
                  <div>
                    <b-img
                      :src="require('@/assets/imgs/DrivenbyValues.png')"
                      fluid
                      alt="Vatika image"
                      class="pl-0"
                      style="height: 25px"
                    ></b-img>
                  </div>
                    <div style="display: flex; justify-content: flex-end">
                      <div v-if="!isAuthenticated">
                        <a title="" class="LoginBtn" @click="showHideLoginFrom()"
                          >Login</a
                        >
                        <!-- @click="showHideLoginFrom()" -->
                      </div>
                    </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12" class="flex" v-if="showLoginForm">
              <form class="login" @submit.prevent="login">
                <div
                  :class="
                    this.showTimer
                      ? 'login-form-auth'
                      : 'login-form popoverForm'
                  "
                >
                  <h1>{{ title }}</h1>

                  <div class="form-group">
                    <div
                      v-if="error.message"
                      class="alert alert-danger"
                      role="alert"
                    >
                      {{ error.message }}
                    </div>
                    <div
                      class="alert alert-success"
                      v-if="status === 'error'"
                      role="alert"
                    >
                      {{ message }}
                    </div>
                    <!-- <div class="" v-if="status === 'verify'">
                      <div class="alert alert-success">
                        OTP was sent to your email address:
                      </div>
                    </div> -->

                    <div v-if="reset" class="alert alert-success">
                      Request completed successfully
                    </div>
                    <b-row>
                      <b-col md="12" style="margin-left:-17px">
                        <b-alert
                          class="b-alert-width-fix"
                          :variant="isSuccess ? 'success' : 'danger'"
                          :show="showAlert"
                          dismissible
                          fade
                          @dismissed="showAlert = false"
                          >{{ responseMsg }}
                        </b-alert>
                      </b-col>
                    </b-row>
                    <div
                      v-if="
                        status != 'setPassword' ||
                          (status === 'success' && status != 'success')
                      "
                    >
                      <label for="username">Username</label>
                      <input
                        type="text"
                        class="form-control"
                        id="username"
                        placeholder="Enter your username"
                        v-model="email"
                        v-on:blur="validateEmail"
                        v-on:focus="
                          error = {
                            message: ''
                          };
                          status = 'login';
                        "
                        :disabled="status != 'login'"
                      />
                    </div>

                    <div
                      v-if="error.reset"
                      class="mt-2 alert alert-danger"
                      role="alert"
                    >
                      {{ error.reset }}
                    </div>
                  </div>

                  <div
                    class="form-group"
                    v-if="status === 'login' || status === 'success'"
                  >
                    <div style="margin-top: -10px;" v-if="showTimerForgot">
                      <label for="password">Password</label>
                      <div
                        style="display: flex; flex-direction: row"
                        class="mt-1"
                      >
                        <div
                          :class="{ disabledPass: showTimer }"
                          style="position: relative;"
                        >
                          <!-- <v-otp-input
                            ref="otpInput"
                            input-classes="otp-input"
                            separator="-"
                            :num-inputs="6"
                            :should-auto-focus="true"
                            :is-input-num="true"
                            input-disabled="true"
                            input-type="password"
                            @on-change="handleOnChange"
                            @on-complete="handleOnComplete"
                          ></v-otp-input> -->
                          <b-form-input
                            :type="showPassword ? 'text' : 'password'"
                            v-model="password"
                            placeholder="Please enter password"
                            style="width: 169%;"
                          />
                          <b-icon
                            class="fa-icon-eye"
                            :icon="showPassword ? 'eye-slash-fill' : 'eye-fill'"
                            aria-hidden="true"
                            @click="togglePassword"
                          ></b-icon>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="form-group"
                    v-if="status === 'verify' && !showTimerForgot"
                  >
                    <div class="timer">
                      <label for="password">OTP</label>
                      <div v-if="showTimerForgotText">
                        <p style="margin-left: 20px;">
                          OTP expires in: {{ formatTime(forgotMinutes) }}:{{
                            formatTime(forgotSeconds)
                          }}
                          minutes
                        </p>
                      </div>
                    </div>
                    <div
                      style="display: flex; flex-direction: row"
                      class="mt-1"
                    >
                      <v-otp-input
                        ref="verifyOtp"
                        input-classes="otp-input"
                        separator="-"
                        :num-inputs="6"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        input-disabled="true"
                        input-type="password"
                        @on-change="handleOnVerifyOTP"
                        @on-complete="handleOnCompleteOTP"
                      />
                    </div>

                    <div class="alert alert-danger" v-if="error.verify">
                      <p>OTP is incorrect</p>
                    </div>
                  </div>
                  <div class="form-group" v-if="showTimer">
                    <div class="timer">
                      <label for="password">OTP</label>
                      <div v-if="showTimerText">
                        <p>
                          OTP expires in: {{ formatTime(timerMinutes) }}:{{
                            formatTime(timerSeconds)
                          }}
                          minutes
                        </p>
                      </div>
                    </div>
                    <div
                      style="display: flex; flex-direction: row"
                      class="mt-1"
                    >
                      <v-otp-input
                        ref="verifyOtp"
                        input-classes="otp-input"
                        separator="-"
                        :num-inputs="6"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        input-disabled="true"
                        input-type="password"
                        @on-change="handleOnVerify2FAOTP"
                        @on-complete="handleOnComplete2FAOTP"
                      />
                    </div>

                    <div class="alert alert-danger" v-if="error.verify">
                      <p>OTP is incorrect</p>
                    </div>
                  </div>

                  <div v-if="status === 'setPassword'">
                    <div class="form-group">
                      <div v-if="error.newPassword">
                        <div class="alert alert-danger">
                          {{ error.newPassword }}
                        </div>
                      </div>
                    </div>

                    <div style="margin-top: -17px;" class="form-group">
                      <label for="password">New Password</label>
                      <div
                        style="display: flex; flex-direction: row"
                        class="mt-1"
                      >
                        <!-- <v-otp-input
                          ref="password"
                          input-classes="otp-input"
                          separator="-"
                          :num-inputs="6"
                          :should-auto-focus="true"
                          :is-input-num="true"
                          input-disabled="true"
                          input-type="password"
                          @on-change="handleOnChange"
                        /> -->
                        <div style="position: relative;">
                          <b-form-input
                            :type="showNewPassword ? 'text' : 'password'"
                            v-model="newPass"
                            placeholder="Please enter password"
                            style="width: 169%;"
                          />
                          <b-icon
                            class="fa-icon-eye"
                            :icon="
                              showNewPassword ? 'eye-slash-fill' : 'eye-fill'
                            "
                            aria-hidden="true"
                            @click="toggleNewPassword"
                          ></b-icon>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="password">Verify Password</label>
                      <div
                        style="display: flex; flex-direction: row"
                        class="mt-1"
                      >
                        <!-- <v-otp-input
                          ref="verifyPassword"
                          input-classes="otp-input"
                          separator="-"
                          :num-inputs="6"
                          :is-input-num="true"
                          input-disabled="true"
                          input-type="password"
                          @on-complete="handleOnCompleteSetPassword"
                        /> -->
                        <div style="position: relative;">
                          <b-form-input
                            :type="showVerifyPassword ? 'text' : 'password'"
                            v-model="verifyPass"
                            placeholder="Please enter password"
                            style="width: 169%;"
                          />
                          <b-icon
                            class="fa-icon-eye"
                            :icon="
                              showVerifyPassword ? 'eye-slash-fill' : 'eye-fill'
                            "
                            aria-hidden="true"
                            @click="toggleVerifyPassword"
                          ></b-icon>
                        </div>
                      </div>
                    </div>
                    <div class="text-white">
                      <b-button
                        style="margin-top: 10px;"
                        id="submit-button"
                        variant="info"
                        @click="setNewChangePass()"
                        >Submit</b-button
                      >
                    </div>
                  </div>
                  <div v-if="status === 'login' || status === 'success'">
                    <b-button
                      id="login-button"
                      variant="info"
                      v-if="!showTimer"
                      @click="login()"
                      >Login</b-button
                    >
                  </div>
                  <p
                    v-if="
                      status != 'verify' &&
                        status != 'success' &&
                        status != 'setPassword' &&
                        !showTimer
                    "
                    @click="resetPassword"
                    :class="isValid ? 'btn' : 'btn disabled'"
                    class="forgot-password"
                  >
                    Forgot Password
                  </p>
                  <div
                    :class="
                      this.showTimerText || this.showTimerForgotText
                        ? 'disabled-resend-otp'
                        : 'forgot-password'
                    "
                  >
                    <p
                      v-if="showTimer || !showTimerForgot"
                      style="margin-top: -5px; font-size: 14px; text-align:center; text-decoration: underline;"
                      @click="sendOtpType()"
                    >
                      Resend OTP
                    </p>
                  </div>
                  <hr
                    style="border-color:black; margin-top: -5px"
                    v-if="gmailAuthFlag && !isPasswordExpired"
                  />
                  <div
                    class="text-center"
                    style="font-size:0.8rem;"
                    v-if="gmailAuthFlag && !isPasswordExpired"
                  >
                    OR
                  </div>
                  <!-- <div>
                  <p
                    :class="isValid ? 'btn' : 'btn disabled'"
                  >
                    <b-button variant="outline-info" style="margin-top: -5px" href="https://accounts.google.com/ServiceLogin/signinchooser?elo=1&ifkv=AWnogHcCP5F2W9mrTMUQrlv3U4tU3Kzl-9I-uhDF1nF_wodENK6npeYckNvdwiAXg_-oTwVYlSsk&flowName=GlifWebSignIn&flowEntry=ServiceLogin" target="_parent"><b-icon-google></b-icon-google> 
                    Sign up with Google
                    </b-button>
                  </p>
                </div> -->
                  <div
                    class="signup-buttons"
                    style="margin-top: 2px; padding-bottom:10px"
                    v-if="gmailAuthFlag && !isPasswordExpired"
                  >
                    <a
                      :href="googleLoginUrl"
                      class="google-signup"
                      style="margin-left: 20%"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        aria-hidden="true"
                      >
                        <g fill="none" fill-rule="evenodd">
                          <path
                            fill="#4285F4"
                            d="M17.64 9.2045c0-.6381-.0573-1.2518-.1636-1.8409H9v3.4814h4.8436c-.2086 1.125-.8427 2.0782-1.7959 2.7164v2.2581h2.9087c1.7018-1.5668 2.6836-3.874 2.6836-6.615z"
                          ></path>
                          <path
                            fill="#34A853"
                            d="M9 18c2.43 0 4.4673-.806 5.9564-2.1805l-2.9087-2.2581c-.8059.54-1.8368.859-3.0477.859-2.344 0-4.3282-1.5831-5.036-3.7104H.9574v2.3318C2.4382 15.9832 5.4818 18 9 18z"
                          ></path>
                          <path
                            fill="#FBBC05"
                            d="M3.964 10.71c-.18-.54-.2822-1.1168-.2822-1.71s.1023-1.17.2823-1.71V4.9582H.9573A8.9965 8.9965 0 0 0 0 9c0 1.4523.3477 2.8268.9573 4.0418L3.964 10.71z"
                          ></path>
                          <path
                            fill="#EA4335"
                            d="M9 3.5795c1.3214 0 2.5077.4541 3.4405 1.346l2.5813-2.5814C13.4632.8918 11.426 0 9 0 5.4818 0 2.4382 2.0168.9573 4.9582L3.964 7.29C4.6718 5.1627 6.6559 3.5795 9 3.5795z"
                          ></path>
                        </g>
                      </svg>
                      Log in with Vatika Email
                    </a>
                  </div>
                </div>
              </form>
            </b-col>
          </b-row>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { validateEmail } from '@/app/services/api';
import { GOOGLE_AUTH_URL } from '../../utility/url.utility';
import URL_UTILITY from '../../utility/url.utility';
import appStrings from '../../utility/string.utility';
import CryptoJS from 'crypto-js';
// import {GOOGLE_AUTH_URL, ACCESS_TOKEN} from '../../utility/googleAuth';

export default {
  mounted() {
    if (sessionStorage.token) {
      this.isAuthenticated = true;
    }
    this.$store.dispatch('auth/setToken');
    this.eventBus.$on('menutTitle', menuTitle => {
      this.activeMenu = menuTitle;
    });
    this.eventBus.$on('setLeData', results => {
      this.leList = results;
    });
  },
  watch: {
    '$route.name': function() {
      if (this.$router.currentRoute.name === 'home') {
        if (!sessionStorage.token) {
          this.isAuthenticated = false;
          this.activeMenu = null;
          this.userName = null;
          this.leList = null;
        }
      }
    }
  },
  data() {
    return {
      notificationCount: 0,
      showLoginForm: false,
      loader: false,
      activeMenu: null,
      leList: null,
      userName: null,
      userDtl: null,
      email: '',
      title: 'Login',
      password: '',
      isValid: false,
      verifyPassword: '',
      error: {},
      status: 'login',
      otp: '',
      message: '',
      reset: false,
      isAuthenticated: false,
      logoProps: { width: '80%', height: 60, class: 'm1' },
      googleLoginUrl: GOOGLE_AUTH_URL,
      showTimer: false,
      timerMinutes: 0,
      timerSeconds: 0,
      showAlert: false,
      responseMsg: '',
      isSuccess: false,
      showTimerText: false,
      timerInterval: null,
      token2FA: null,
      otp2FA: null,
      gmailAuthFlag: false,
      storeTime: null,
      resend2FAOTPFlag: false,
      forgotSeconds: 0,
      forgotMinutes: 0,
      showTimerForgotText: false,
      showTimerForgot: true,
      timerIntervalForgot: null,
      otpType: null,
      storeForgotTime: null,
      isPasswordExpired: false,
      newPass: null,
      verifyPass: null,
      showPassword: false,
      showNewPassword: false,
      showVerifyPassword: false,
      downloadGstUserManualUrl: URL_UTILITY.getGstManualDocumentUrl,
      userManualGstFile: appStrings.GSTUSERMANUALFILENAME,
      // googleLoginUrl:
      //   'https://realapp.in:8080/realapp/oauth2/authorize/google?redirect_uri=http://localhost:3000/oauth2/redirect'
      // googleLoginUrl:
      //   'https://realapp.in:8080/realapp/oauth2/authorize/google?redirect_uri=http://localhost:8080/oauth2/redirect'
    };
  },
  computed: {
    showUserManualLink() {
      const allowedRoutes = [
        'gstr2adwnl',
        'gstItcSummaryRpt',
        'srchitcdataupdation',
        'srchItcRec',
        'SrchPartyVerification',
        'getNearMatchForm',
        'gstr3bSummary',
        'gstItcSupplierSummaryRpt',
        'vendorCom',
        'reco_errors',
        'gst_r1_summary',
        'salesRegsiter',
        'SrchEinvoicing',
        'srchewaybill',
        'gstcredientials',
        'gstmaster',
        'gstmstmpng',
        'gstsetup',
        'vendorHoldMapping',
        'vendorEmailSetup',
        'compDash',
        'performanceMatrix',
        'company_compliance'
      ];
      return allowedRoutes.includes(this.$route.name);
    }
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    toggleNewPassword() {
      this.showNewPassword = !this.showNewPassword;
    },
    toggleVerifyPassword() {
      this.showVerifyPassword = !this.showVerifyPassword;
    },
    getNotificationCount() {
      const userId = this.$store.state.auth.userId;
      if (userId) {
        const payload = {
          user_id: userId
        };
        this.$store
          .dispatch('intWorkflow/getWorkflowNotiCount', payload)
          .then(response => {
            if (response.status === 200) {
              this.notificationCount = response.data.data;
            }
          });
      }
    },
    showHideLoginFrom() {
      this.showLoginForm = !this.showLoginForm;
      if(this.showLoginForm){
        this.getGmailAuth()
      }
    },
    homeIconClikced() {
      this.activeMenu = null;
      this.$store.dispatch('shared/setHomeIconClicked');
    },
    showModal() {
      this.$refs['my-modal'].show();
    },
    getUsersDetails() {
      if (sessionStorage.token) {
        this.$store.dispatch('auth/getUserDetail').then(response => {
          if (response.status === 200) {
            this.userName = response.data.data.user_name;
            this.userDtl = response.data.data;
            this.$store.dispatch('auth/setUserId', response.data.data.user_id);
            this.$store.dispatch(
              'auth/setUserName',
              response.data.data.user_name
            );
            this.$store.dispatch('auth/setUserDtl', response.data.data);
            this.getNotificationCount();
          }
        });
      }
    },
    getGmailAuth() {
      // if (!this.showLoginForm) {
        this.$store.dispatch('auth/getGmailAuth').then(response => {
          if (response.status === 200) {
            if (response.data) {
              this.gmailAuthFlag = true;
              // this.showHideLoginFrom();
            }
          } else {
            this.gmailAuthFlag = false;
            // this.showHideLoginFrom();
          }
        });
      // }
        // this.showHideLoginFrom();
    },
    validateEmail() {
      const email = this.email;
      const isEmailValid = validateEmail(email);
      isEmailValid
        .then(resp => {
          if (resp.status == 200) this.isValid = true;
          else {
            this.error = {
              message: 'User not found',
              user: null
            };
          }
        })
        .catch(err => {
          this.error = {
            message: 'User not found',
            user: err
          };
        });
    },
    handleOnChange: function(value) {
      this.verifyPassword = value;
      this.error = {
        setPassword: ''
      };
      this.reset = false;
    },
    handleOnComplete: function(value) {
      this.password = value;
      this.login();
    },
    handleOnCompleteSetPassword: function(value) {
      this.password = value;
      if (this.password != this.verifyPassword) {
        // this.error = {
        //   setPassword: 'Password not match'
        // };
        this.showAlert = true;
        this.isSuccess = false;
        this.responseMsg = 'Password not match';
        this.isPasswordExpired = false;
        clearInterval(this.timerIntervalForgot);
        this.$refs.password.clearInput();
        this.$refs.verifyPassword.clearInput();
      } else {
        this.setPassword();
      }
    },
    setNewChangePass: function() {
      if (this.newPass != this.verifyPass) {
        // this.error = {
        //   setPassword: 'Password not match'
        // };
        this.showAlert = true;
        this.isSuccess = false;
        this.responseMsg = 'Password not match';
        this.isPasswordExpired = false;
        clearInterval(this.timerIntervalForgot);
        // this.$refs.password.clearInput();
        // this.$refs.verifyPassword.clearInput();
        this.newPass = null;
        this.verifyPass = null;
        this.gmailAuthFlag = false;
      } else {
        this.setNewPass();
      }
    },
    setNewPass: function() {
      if (this.newPass != '' && this.verifyPass != '') {
        const user = {
          new_password: this.newPass,
          user_email: this.email
        };
        this.$store
          .dispatch('auth/setNewPassword', user)
          .then(resp => {
            if (resp.status === 200) {
              this.status = 'login';
              // this.reset = true;
              this.showAlert = true;
              this.isSuccess = true;
              this.responseMsg = 'Password is changed successfully';
              // this.getGmailAuth();
              this.title = 'Login';
              this.password = null;
              this.gmailAuthFlag = true;
              this.newPass = null;
              this.verifyPass = null;
              this.isPasswordExpired = false;
              clearInterval(this.timerIntervalForgot);
            } else {
              // this.error = {
              //   newPassword: resp.response.data.message
              // };
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = resp.response.data.message;
              // setTimeout(() => {
              //   this.showAlert = false;
              //   this.isSuccess = false;
              //   this.responseMsg = '';
              // }, 5000);
              // this.$refs.password.clearInput();
              // this.$refs.verifyPassword.clearInput();
              this.newPass = null;
              this.verifyPass = null;
              this.gmailAuthFlag = false;
            }
          })
          .catch(err => {
            this.error = {
              newPassword: err
            };
            this.status = 'setPassword';
          });
      } else {
        this.error = {
          required: 'Password field(s) cannot be empty'
        };
      }
    },
    setPassword: function() {
      if (this.password != '' && this.verifyPassword != '') {
        const user = {
          new_password: this.password,
          user_email: this.email
        };
        this.$store
          .dispatch('auth/setNewPassword', user)
          .then(resp => {
            if (resp.status === 200) {
              this.status = 'login';
              // this.reset = true;
              this.showAlert = true;
              this.isSuccess = true;
              this.responseMsg = 'Password is changed successfully';
              // this.getGmailAuth();
              this.title = 'Login';
              this.gmailAuthFlag = true;
              this.isPasswordExpired = false;
              clearInterval(this.timerIntervalForgot);
            } else {
              // this.error = {
              //   newPassword: resp.response.data.message
              // };
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = resp.response.data.message;
              setTimeout(() => {
                this.showAlert = false;
                this.isSuccess = false;
                this.responseMsg = '';
              }, 5000);
              this.$refs.password.clearInput();
              this.$refs.verifyPassword.clearInput();
            }
          })
          .catch(err => {
            this.error = {
              newPassword: err
            };
            this.status = 'setPassword';
          });
      } else {
        this.error = {
          required: 'Password field(s) cannot be empty'
        };
      }
    },
    handleOnVerifyOTP: function(value) {
      this.error = {
        verify: ''
      };
      this.otp = value;
    },
    handleOnCompleteOTP: function(value) {
      this.otp = value;
      this.forgotPassword();
    },
    handleOnVerify2FAOTP: function(value) {
      this.error = {
        verify: ''
      };
      this.otp2FA = value;
    },
    handleOnComplete2FAOTP: function(value) {
      this.otp2FA = value;
      this.verify2FAOTP();
    },
    startTimer() {
      this.otpType = 'passwordType';
      this.showTimer = true;
      this.showTimerText = true;
      this.timerMinutes = this.storeTime;
      this.timerSeconds = 0;
      this.timerInterval = setInterval(() => {
        if (this.timerMinutes === 0 && this.timerSeconds === 0) {
          clearInterval(this.timerInterval);
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = 'OTP expired';
          this.showTimerText = false;
          this.showTimerText = false;
          this.timerInterval = null;
          setTimeout(() => {
            if (this.timerMinutes === 0 && this.timerSeconds === 0) {
              this.showAlert = false;
            }
          }, 60000);
          setTimeout(() => {
            if (this.timerMinutes === 0 && this.timerSeconds === 0) {
              this.showTimer = false;
              this.$refs.otpInput.clearInput();
            }
          }, 60000);
        } else {
          if (this.timerSeconds === 0) {
            this.timerMinutes -= 1;
            this.timerSeconds = 59;
          } else {
            this.timerSeconds -= 1;
          }
        }
      }, 1000);
    },
    sendOTP() {
      if (this.timerInterval) {
        return;
      }
      this.startTimer();
      this.resend2FAOTP();
    },
    formatTime(value) {
      return value < 10 ? `0${value}` : value;
    },
    resetPassword: function() {
      const user_email = this.email;
      if (this.email != '') {
        this.$store
          .dispatch('auth/passwordReset', { user_email })
          .then(resp => {
            if (resp.status === 200) {
              this.title = 'Forgot Password';
              // this.message = 'Request completed successfully';
              clearInterval(this.timerIntervalForgot);
              this.status = 'verify';
              this.showAlert = true;
              this.isSuccess = true;
              this.responseMsg = 'OTP was sent to your email address';
              const decryptRes = JSON.parse(                 
                this.decryptResponse(resp.data.data)
              );
              this.storeForgotTime = +decryptRes;
              this.forgotPassStartTimer();
              setTimeout(() => {
                this.showAlert = false;
                this.isSuccess = false;
                this.responseMsg = '';
              }, 5000);
            } else {
              this.error = {
                reset: resp.response.data.message
              };
            }
          })
          .catch(err => {
            this.error = {
              reset: err
            };
            this.status = 'reset';
          });
      } else {
        this.error = {
          reset: 'Email field is required'
        };
      }
    },
    forgotPassStartTimer() {
      this.otpType = 'forgotType';
      this.showTimerForgot = false;
      this.showTimerForgotText = true;
      this.forgotMinutes = this.storeForgotTime;
      this.forgotSeconds = 0;
      this.timerIntervalForgot = setInterval(() => {
        if (this.forgotMinutes === 0 && this.forgotSeconds === 0) {
          clearInterval(this.timerIntervalForgot);
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = 'OTP expired';
          this.showTimerForgotText = false;
          this.showTimerText = false;
          this.timerIntervalForgot = null;
          setTimeout(() => {
            if (this.forgotMinutes === 0 && this.forgotSeconds === 0) {
              this.showAlert = false;
            }
          }, 60000);
          setTimeout(() => {
            if (this.forgotMinutes === 0 && this.forgotSeconds === 0) {
              this.showTimerForgot = true;
              this.status = 'login';
              this.$refs.otpInput.clearInput();
            }
          }, 60000);
        } else {
          if (this.forgotSeconds === 0) {
            this.forgotMinutes -= 1;
            this.forgotSeconds = 59;
          } else {
            this.forgotSeconds -= 1;
          }
        }
      }, 1000);
    },
    forgotSendOTP() {
      if (this.timerIntervalForgot) {
        return;
      }
      this.forgotPassStartTimer();
      this.resend2FAOTP();
    },
    sendOtpType() {
      if (this.otpType === 'passwordType') {
        this.sendOTP();
      } else if (this.otpType === 'forgotType') {
        this.forgotSendOTP();
      }
    },
    forgotPassword: function() {
      const user = {
        user_email: this.email,
        otp: this.otp
      };
      this.$store
        .dispatch('auth/confirmOTP', user)
        .then(resp => {
          if (resp.status === 200) {
            this.status = 'setPassword';
            this.showTimerForgot = true;
            this.newPass = null;
            this.verifyPass = null;
            this.gmailAuthFlag = false;
            clearInterval(this.timerIntervalForgot);
          } else {
            // this.error = {
            //   message: resp.response.data.message
            // };
            alert(
              'Your entered OTP is incorrect please procced with forgot password again'
            );
            this.showTimerForgot = true;
            this.status = 'login';
          }
        })
        .catch(err => {
          this.error = {
            message: err
          };
        });
    },
    verify2FAOTP: function() {
      const payload = {
        user_email: this.email,
        otp: this.otp2FA
      };
      this.$store
        .dispatch('auth/verify2FAOTP', payload)
        .then(response => {
          if (response.status === 200) {
            this.getResponsibility(this.token2FA).then(resp => {
              sessionStorage.setItem('token', this.token2FA);
              const responsibilitys = JSON.stringify(resp.data.responsibilitys);
              localStorage.setItem('responsibilities', responsibilitys);
              this.getUsersDetails();
              this.$router.push('/dashboard');
              window.location = '/dashboard';
            });
          } else {
            // alert(response.response.data.message);
            alert(
              'Your entered OTP is incorrect please procced with login again'
            );
            this.showTimer = false;
            clearInterval(this.timerInterval);
            // this.$refs.otpInput.clearInput();
            this.password = null;
          }
        })
        .catch(err => {
          this.error = {
            message: err
          };
        });
    },
    resend2FAOTP: function() {
      this.showAlert = false;
      const payload = {
        user_email: this.email
      };
      this.$store
        .dispatch('auth/resend2FAOTP', payload)
        .then(response => {
          this.resend2FAOTPFlag = response.data;
        })
        .catch(err => {
          this.error = {
            message: err
          };
        });
    },
    decryptResponse(encryptedData) {
      const key = 'QUtDTUVGWEhKTEtMTVlaUA==';
      const keyForCryptoJS = CryptoJS.enc.Base64.parse(key);
      const decodeBase64 = CryptoJS.enc.Base64.parse(encryptedData);
      const decryptedData = CryptoJS.AES.decrypt(
        {
          ciphertext: decodeBase64
        },
        keyForCryptoJS,
        {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        }
      );
      return decryptedData.toString(CryptoJS.enc.Utf8);
    },
    login: function() {
      let user_email = this.email;
      let password = this.password;
      this.loader = true;
      this.$store
        .dispatch('auth/login', { user_email, password })
        .then(response => {
          this.loader = false;
          const token = response.data.accessToken;
          // this.storeTime = 1;
          const encryptedData = response.data.token2fa;
          const decryptData = JSON.parse(this.decryptResponse(encryptedData));
          const is2faEnable = decryptData.is2faEnable;
          this.storeTime = +decryptData.time2fa;
          // localStorage.setItem(
          //   'notification',
          //   JSON.stringify(response.data.data.notification_status)
          // );
          // this.loader = false;
          // this.isAuthenticated = true;
          // this.getUsersDetails();
          // this.showHideLoginFrom();
          // this.getNotificationCount();
          // this.$router.push('/dashboard');
          if (response.data.isPasswordExpired) {
            this.isPasswordExpired = true;
            this.status = 'setPassword';
            this.title = 'Change Password';
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg =
              'Your password is expired please change your password';
          } else {
            if (token && !is2faEnable) {
              this.getResponsibility(token).then(resp => {
                sessionStorage.setItem('token', token);
                const responsibilitys = JSON.stringify(
                  resp.data.responsibilitys
                );
                localStorage.setItem('responsibilities', responsibilitys);
                this.getUsersDetails();
                this.$router.push('/dashboard');
                window.location = '/dashboard';
              });
            } else if (is2faEnable) {
              this.token2FA = token;
              this.timerMinutes = response.data.time2fa;
              this.startTimer();
            }
          }
        })
        .catch(err => {
          this.loader = false;
          this.error = {
            message: err
          };
          setTimeout(() => {
            this.error = {
              message: ''
            };
          }, 5000);
          this.password = null;
          this.$refs.otpInput.clearInput();
        });
    },
    getResponsibility(token) {
      const headers = new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        method: 'GET'
      });
      const options = {
        headers: headers,
        method: 'GET'
      };
      const url = URL_UTILITY.responsibility;
      return fetch(url, options).then(response =>
        response.json().then(json => {
          if (!response.ok) {
            return Promise.reject(json);
          }
          return json;
        })
      );
    },
    singout: function() {
      this.$store.dispatch('auth/logout').then(() => {
        this.leList = null;
        location.reload(true);
      });
    },
    showNotificationSummary() {
      this.$router.push('/dashboard/notification/notificationSummary');
      this.activeMenu = 'Notification Summary';
    },
    showChangePasswordForm() {
      this.$router.push('/dashboard/changePassword/changePasswordForm');
      this.activeMenu = 'Change Password';
    }
  },
  created() {
    setInterval(() => {
      this.getNotificationCount();
    }, 60000);
    this.getUsersDetails();
  }
};
</script>

<style>
.form-group {
  padding: 0 15px;
}
.noti-counter {
  top: -7px;
  color: #fff;
  right: 0px;
  width: 20px;
  height: 18px;
  position: absolute;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  line-height: 18px;
  border-radius: 50%;
  background-color: #ff748e;
}
.otp-input {
  width: 100%;
  height: 40px;
  padding: 5px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
.error {
  border: 1px solid red !important;
}
.user-name {
  font-weight: 600;
  padding-top: 15px;
  font-size: 0.9rem;
}
.login-section {
  display: flex !important;
}
.navbar {
  padding: 0.2rem 1rem !important;
}
.forgot-password {
  text-decoration: underline;
  cursor: pointer;
}
.disabled-resend-otp {
  text-decoration: underline;
  pointer-events: none;
  opacity: 0.6;
}
.nav-link {
  padding: 0.8rem 0.5rem !important;
}
.timer {
  display: grid;
  grid-template-columns: 28% 72%;
}
.timer > div > p {
  font-size: 12px;
  margin-top: 2px;
  margin-left: 37px;
}
.disabledPass {
  pointer-events: none;
  opacity: 0.5;
}
.b-alert-width-fix {
  min-width: 110%;
}
.b-alert-width-fix > button {
  margin-right: -10px;
}
#login-button,
#submit-button {
  margin-left: 24px;
  font-weight: bold;
  font-size: 10px;
  width: 86%;
}
.fa-icon-eye {
  position: absolute;
  right: -110px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.userManualBtn {
  margin-right: 20px;
  margin-top: 10px;
  padding: 0.3rem 1rem !important;
}
</style>
