import store from '../../../../store/';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'ValueSetModal',
  props: [
    'parent_value_set_id',
    'tablePrameter',
    'isDependent',
    'catTypeForSpec',
    'mandatoryParamObj',
    'taxType',
    'depVsetParam',
    'depTowerParam',
    'depFloorParam',
    'depPrjParam',
    'formName',
    'wf_res_id',
    'wf_user_id',
    'gstAssetParam',
    'fusion_key'
  ],
  watch: {
    currentPage: function() {
      this.getLOVBySetType(this.valueSetName);
    }
  },
  data() {
    return {
      valueSetName: null,
      multiSelect: false,
      showModal: false,
      loader: false,
      showAlert: false,
      responseMsg: '',
      isSuccess: false,
      searchText: null,
      valueSetList: [],
      valueSetFields: [
        {
          key: 'multiselect',
          class: 'd-none'
        }
      ],
      multiSelectList: [],
      totalRows: null,
      currentPage: 1,
      perPage: 10
    };
  },
  mounted() {
    this.eventBus.$off('valueSetCode');
    this.eventBus.$on('valueSetCode', valueObj => {
      this.valueSetName = valueObj.valueSetName;
      this.multiSelect = valueObj.multiFlag ? valueObj.multiFlag : null;
      if (!this.isParentLOVSelected(this.valueSetName)) {
        this.alertByValuesetType(this.valueSetName);
      } else {
        this.showModal = true;
        this.currentPage = 1;
        this.getLOVBySetType(this.valueSetName);
      }
    });
  },
  methods: {
    getLOVBySetType(valueSetName) {
      this.loader = true;
      const payload = {
        valueSetName: valueSetName,
        dependentVariable: {
          search_key: this.searchText,
          _page: this.currentPage - 1
        }
      };
      if (
        valueSetName === appStrings.VALUESETTYPE.OU_LIST ||
        valueSetName === appStrings.VALUESETTYPE.GST_OPERATING_UNIT ||
        valueSetName === appStrings.VALUESETTYPE.NFA_LOCATION ||
        valueSetName === appStrings.VALUESETTYPE.BILLING_CYCLE ||
        valueSetName === appStrings.VALUESETTYPE.GL_PERIOD ||
        valueSetName === appStrings.VALUESETTYPE.GSTR3B_PERIOD ||
        valueSetName === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN ||
        valueSetName === appStrings.VALUESETTYPE.ASSET_BOOK_TYPE ||
        valueSetName === appStrings.VALUESETTYPE.FINANCIAL_YEAR_LIST ||
        valueSetName === appStrings.VALUESETTYPE.COF_CAT_BY_LE ||
        valueSetName === appStrings.VALUESETTYPE.ASSET_SPOTON_LOC_VSET ||
        valueSetName === appStrings.VALUESETTYPE.PARTY_REQ_ACCOUNTING_VSET
      ) {
        payload.dependentVariable.le_id = this.parent_value_set_id;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.TENANT_LEASE_ESC_NUM
      ) {
        payload.dependentVariable.lease_tenant_agreement_hdr_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        payload.dependentVariable.le_id = this.parent_value_set_id;
        payload.dependentVariable.ou_id = this.depVsetParam;
      } else if (valueSetName === appStrings.VALUESETTYPE.FMS_PROJECT) {
        payload.dependentVariable.le_id = this.parent_value_set_id;
        payload.dependentVariable.ou_id = this.depVsetParam;
      } else if (valueSetName === appStrings.VALUESETTYPE.SOURCE_TYPE) {
        payload.dependentVariable.ou_id = this.parent_value_set_id;
        payload.dependentVariable.le_id = this.depVsetParam;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.INVENTORY_ORG_LIST ||
        valueSetName === appStrings.VALUESETTYPE.SELECT_CRM_PROJECT
      ) {
        payload.dependentVariable.ou_id = this.parent_value_set_id;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.ERP_SITE_NAME ||
        valueSetName === appStrings.VALUESETTYPE.MANUAL_PAYMENT_EXTERNAL_BANK_AC
      ) {
        payload.dependentVariable.vendor_site_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.ERP_INVENTORY_NAME) {
        payload.dependentVariable.inv_id = this.parent_value_set_id;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.AR_TRX_TYPE_APPLY_UNAPPLY_VSET
      ) {
        payload.dependentVariable.module_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.SUB_MODULE) {
        payload.dependentVariable.module_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.ERP_LOCATION_NAME) {
        payload.dependentVariable.loc_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.LOCATION) {
        payload.dependentVariable.inv_org_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.CCID_BY_LE) {
        payload.dependentVariable.le_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.VENDOR_SITE_VSET) {
        payload.dependentVariable.vendor_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.FMS_COMP_TABLE) {
        payload.dependentVariable.fms_comp_group_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.PRJ_POCKET) {
        payload.dependentVariable.sector_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.PRJ_PROJECT) {
        payload.dependentVariable.pocket_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
        payload.dependentVariable.lease_prj_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
        payload.dependentVariable.lease_tower_id = this.parent_value_set_id;
        payload.dependentVariable.lease_prj_id = this.depVsetParam;
      } else if (valueSetName === appStrings.VALUESETTYPE.LEASE_UNIT_LIST) {
        payload.dependentVariable.lease_tower_id = this.depTowerParam;
        payload.dependentVariable.lease_floor_id = this.parent_value_set_id;
        payload.dependentVariable.lease_prj_id = this.depVsetParam;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.LEASE_UNIT_LIST_FOR_FMS
      ) {
        payload.dependentVariable.lease_prj_id = this.depVsetParam;
        payload.dependentVariable.lease_tower_id = this.parent_value_set_id.lease_tower_id;
        payload.dependentVariable.lease_floor_id = this.parent_value_set_id.lease_floor_id;
        payload.dependentVariable.customer_id = this.parent_value_set_id.customer_id;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.LEASE_CUSTOMER_LIST_FOR_FMS
      ) {
        payload.dependentVariable.lease_prj_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.BILLING_PLAN) {
        payload.dependentVariable.billing_cycle_id = this.parent_value_set_id;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.BILLING_PLAN_BY_COMP_ID
      ) {
        payload.dependentVariable.comp_id = this.parent_value_set_id;
        payload.dependentVariable.fms_prj_id = this.depVsetParam;
      } else if (valueSetName === appStrings.VALUESETTYPE.BILL_CYCLE_PERIOD) {
        payload.dependentVariable.billing_cycle_hdr_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.SELECT_CRM_TOWER) {
        payload.dependentVariable.crm_prj_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.MODULE_LIST) {
        payload.dependentVariable.flag = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.SELECT_CRM_FLOOR) {
        payload.dependentVariable.crm_tower_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.SELECT_CRM_UNIT) {
        payload.dependentVariable.crm_floor_id = this.parent_value_set_id;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.AR_ACTIVITY_MASTER_VSET
      ) {
        payload.dependentVariable.ou_id = this.parent_value_set_id;
        payload.dependentVariable.prj_id = this.depVsetParam;
      } else if (valueSetName === appStrings.VALUESETTYPE.CRM_SECTOR) {
        payload.dependentVariable.crm_master_prj_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.FMS_TAX_CAT) {
        payload.dependentVariable.fms_prj_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.LEASE_TAX_CAT) {
        payload.dependentVariable.lease_prj_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.CRM_POCKET) {
        payload.dependentVariable.crm_sector_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.PRJ_SECTOR) {
        payload.dependentVariable.master_prj_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.NFA_FMS_POCKET) {
        payload.dependentVariable.sector_id = this.parent_value_set_id;
        payload.dependentVariable.user_id = store.state.auth.userId;
      } else if (valueSetName === appStrings.VALUESETTYPE.NFA_FMS_PROJECT) {
        payload.dependentVariable.pocket_id = this.parent_value_set_id;
        payload.dependentVariable.le_id = this.depVsetParam;
        payload.dependentVariable.user_id = store.state.auth.userId;
      } else if (valueSetName === appStrings.VALUESETTYPE.NFA_FMS_TOWER) {
        payload.dependentVariable.prj_id = this.parent_value_set_id;
        payload.dependentVariable.user_id = store.state.auth.userId;
      } else if (valueSetName === appStrings.VALUESETTYPE.NFA_FMS_FLOOR) {
        payload.dependentVariable.prj_id = this.depVsetParam;
        payload.dependentVariable.tower_id = this.parent_value_set_id;
        payload.dependentVariable.user_id = store.state.auth.userId;
      } else if (valueSetName === appStrings.VALUESETTYPE.NFA_FMS_UNIT) {
        payload.dependentVariable.prj_id = this.depVsetParam;
        payload.dependentVariable.tower_id = this.depTowerParam;
        payload.dependentVariable.floor_id = this.parent_value_set_id;
        payload.dependentVariable.user_id = store.state.auth.userId;
      } else if (valueSetName === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT) {
        payload.dependentVariable.prj_id = this.depVsetParam;
        payload.dependentVariable.tower_id = this.depTowerParam;
        payload.dependentVariable.floor_id = this.depFloorParam;
        payload.dependentVariable.unit_id = this.parent_value_set_id;
        payload.dependentVariable.user_id = store.state.auth.userId;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.NFA_OUTWRIGHT_FMS_SUB_UNIT
      ) {
        payload.dependentVariable.prj_id = this.depVsetParam;
        payload.dependentVariable.tower_id = this.depTowerParam;
        payload.dependentVariable.floor_id = this.depFloorParam;
        payload.dependentVariable.unit_id = this.parent_value_set_id;
        payload.dependentVariable.user_id = store.state.auth.userId;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
      ) {
        payload.dependentVariable.tax_group_id = this.parent_value_set_id;
        payload.dependentVariable.tax_type = this.taxType;
      } else if (valueSetName === appStrings.VALUESETTYPE.INV_MASTER_ORG) {
        payload.dependentVariable.is_master_org = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.BRANCHES_BY_PARTY) {
        payload.dependentVariable.party_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.ACCOUNTS_BY_BRANCH) {
        payload.dependentVariable.bank_branch_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.FMS_COMPONENT) {
        payload.dependentVariable.comp_group_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.FMS_PRJ_BY_USER_ID) {
        payload.dependentVariable.user_id = this.parent_value_set_id;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_BY_USER
      ) {
        payload.dependentVariable.prj_id = this.parent_value_set_id;
        payload.dependentVariable.user_id = this.depVsetParam;
      } else if (
        valueSetName ===
        appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_BY_TIMESHEET_SUMMARY
      ) {
        payload.dependentVariable.prj_id = this.parent_value_set_id;
        payload.dependentVariable.user_id = this.depVsetParam;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_TIMESHEET
      ) {
        payload.dependentVariable.prj_id = this.parent_value_set_id;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.BILLING_PLAN_NAME_BY_TIMESHEET
      ) {
        payload.dependentVariable.sales_agreement_hdr_id = this.parent_value_set_id;
      } else if (
        valueSetName ===
        appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP_BY_TIMESHEET
      ) {
        payload.dependentVariable.sales_agreement_hdr_id = this.parent_value_set_id;
        payload.dependentVariable.bill_plan_hdr_id = this.depVsetParam;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP
      ) {
        payload.dependentVariable.sales_agreement_hdr_id = this.parent_value_set_id;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP_DTL
      ) {
        payload.dependentVariable.sales_agrrement_comp_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.SHIFT_NAME) {
        payload.dependentVariable.workday_hdr_id = this.parent_value_set_id;
      } else if (
        valueSetName ===
        appStrings.VALUESETTYPE.MASTER_LEASE_RECEIVING_BANK_VSET
      ) {
        payload.dependentVariable.entity_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.ASSET_CATEGORY) {
        payload.dependentVariable.book_type_code = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.TRX_TYPE) {
        payload.dependentVariable.module_id = this.parent_value_set_id;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.PARTY_RECEIPT_METHODS
      ) {
        payload.dependentVariable.module_id = this.parent_value_set_id;
        payload.dependentVariable.le_id = this.depVsetParam;
        payload.dependentVariable.entity_id = this.depPrjParam;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.FMS_CUSTOMER_BY_SUBUNIT
      ) {
        payload.dependentVariable.fms_sub_unit_id = this.parent_value_set_id;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.FMS_CUSTOMER_BY_PROJ
      ) {
        payload.dependentVariable.fms_prj_id = this.parent_value_set_id;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.LEASE_CUSTOMER_BY_UNIT
      ) {
        payload.dependentVariable.lease_prj_unit_id = this.parent_value_set_id;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.LEASE_CUSTOMER_BY_PRJ
      ) {
        payload.dependentVariable.lease_prj_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.PAYMENT_SERIES_AP) {
        payload.dependentVariable.payment_mode = this.parent_value_set_id;
        payload.dependentVariable.bank_account_id = this.depVsetParam;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.PARY_REQ_ADDRESS_BY_REQ_ID_VSET
      ) {
        payload.dependentVariable.party_req_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.SELECT_STATE) {
        payload.dependentVariable.country_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.BANK_ACC_SCHEDULE) {
        payload.dependentVariable.party_site_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.SELECT_CITY) {
        payload.dependentVariable.state_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.SELECT_PINCODE) {
        payload.dependentVariable.state_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.BACKUP_TABLES) {
        payload.dependentVariable.module_name = this.parent_value_set_id;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.FUSION_BUSINESS_UNIT
      ) {
        payload.dependentVariable.le_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.CHALLAN_LOC) {
        payload.dependentVariable.le_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.CMS_OU) {
        payload.dependentVariable.org_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.BANK_MODE_ACCOUNT) {
        payload.dependentVariable.le_id = this.parent_value_set_id;
        payload.dependentVariable.bank_branch_id = this.depVsetParam;
      } else if (valueSetName === appStrings.VALUESETTYPE.FA_ASSET_ITEMS) {
        payload.dependentVariable.item_type = this.parent_value_set_id;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.ASSET_MASTER_DETAILS_VSET
      ) {
        payload.dependentVariable.hr_loc_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.ASSET_LOCATION) {
        payload.dependentVariable.ORG_LOCATION_ID = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.LOCATOR_VSET) {
        payload.dependentVariable.fa_location_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.FA_ITEM_PROCESSOR) {
        payload.dependentVariable.item_id = this.parent_value_set_id;
      } else if (valueSetName == 'GET_ASSET_MASTER') {
        payload.dependentVariable.item_code = this.parent_value_set_id;
        payload.dependentVariable.grn_num = this.depVsetParam;
        payload.dependentVariable.po_number = this.gstAssetParam;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.FMS_UNIT_DISPLAY ||
        valueSetName === appStrings.VALUESETTYPE.FMS_SUB_UNIT_DISPLAY
      ) {
        payload.dependentVariable.FMS_PRJ_ID = this.parent_value_set_id;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.FMS_UNIT_DISPLAY_VSET ||
        valueSetName === appStrings.VALUESETTYPE.FMS_SUB_UNIT_DISPLAY_VSET ||
        valueSetName === appStrings.VALUESETTYPE.LEASE_UNIT_VSET
      ) {
        payload.dependentVariable.FMS_PRJ_ID = this.parent_value_set_id;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.CUSTOMER_SITE_VSET
      ) {
        payload.dependentVariable.customer_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.SOURCE_LE_OU_MOD) {
        payload.dependentVariable.le_id = this.parent_value_set_id;
        payload.dependentVariable.ou_id = this.depVsetParam;
        payload.dependentVariable.module_id = this.gstAssetParam;
      } else if (
        this.formName === 'accDetails' &&
        valueSetName === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST
      ) {
        payload.dependentVariable.user_id = store.state.auth.userId
          ? store.state.auth.userId
          : this.wf_user_id;
        payload.dependentVariable.res_id = store.state.shared.responsibilityId
          ? store.state.shared.responsibilityId
          : this.wf_res_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.LEASE_PRJ_VSET) {
        payload.dependentVariable.le_id = this.parent_value_set_id;
        payload.dependentVariable.ou_id = this.depVsetParam;
      } else if (valueSetName === appStrings.VALUESETTYPE.RFP_REGION) {
        payload.dependentVariable.country_id = this.parent_value_set_id;
      } else {
        payload.dependentVariable.user_id = store.state.auth.userId;
        payload.dependentVariable.res_id = store.state.shared.responsibilityId;
        payload.dependentVariable.parent_value_set_id = this.parent_value_set_id;
        payload.dependentVariable.table_name =
          valueSetName === appStrings.VALUESETTYPE.TABLE_COLUMNS
            ? this.tablePrameter
            : null;
        payload.dependentVariable.cat_type =
          valueSetName === appStrings.VALUESETTYPE.WF_CRITERIA_SPEC
            ? this.catTypeForSpec
            : null;
      }
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.valueSetFields = [];
            const result = response.data.data;
            const firstObj =
              result.data && result.data.length > 0
                ? result.data[0]
                : result.page && result.page.length > 0
                ? result.page[0]
                : [];
            if (result.columns) {
              const tabelFields = result.columns.map(column => {
                return {
                  key: column.name,
                  class: !column.display ? 'd-none' : ''
                };
              });
              this.valueSetFields = tabelFields;
              if (this.multiSelect) {
                this.valueSetFields.unshift({
                  key: 'multiselect'
                });
              }
              const newResult = result.data.map(data => {
                data.multiSelect = false;
                return data;
              });
              this.valueSetList = newResult;
              this.totalRows = result.total_elements;
            } else {
              for (let val in firstObj) {
                if (typeof firstObj[val] === 'string') {
                  this.valueSetFields.push({ key: val });
                } else {
                  this.valueSetFields.push({ key: val, class: 'd-none' });
                }
              }

              this.valueSetList = result.page;
              this.totalRows = result.total_elements;
            }
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    isParentLOVSelected(valueSetName) {
      return ((valueSetName === appStrings.VALUESETTYPE.OU_LIST ||
        valueSetName === appStrings.VALUESETTYPE.GST_OPERATING_UNIT ||
        valueSetName === appStrings.VALUESETTYPE.GL_PERIOD ||
        valueSetName === appStrings.VALUESETTYPE.GSTR3B_PERIOD ||
        valueSetName === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN ||
        valueSetName === appStrings.VALUESETTYPE.SELECT_CRM_TOWER ||
        (valueSetName === appStrings.VALUESETTYPE.SELECT_CRM_PROJECT &&
          this.isDependent) ||
        valueSetName === appStrings.VALUESETTYPE.SELECT_CRM_FLOOR ||
        valueSetName === appStrings.VALUESETTYPE.SELECT_CRM_UNIT ||
        valueSetName === appStrings.VALUESETTYPE.AR_ACTIVITY_MASTER_VSET ||
        valueSetName === appStrings.VALUESETTYPE.CRM_SECTOR ||
        valueSetName === appStrings.VALUESETTYPE.FMS_COMPONENT ||
        valueSetName === appStrings.VALUESETTYPE.PRJ_PROJECT ||
        valueSetName === appStrings.VALUESETTYPE.CRM_POCKET ||
        valueSetName === appStrings.VALUESETTYPE.FMS_CUSTOMER_BY_SUBUNIT ||
        valueSetName === appStrings.VALUESETTYPE.FMS_CUSTOMER_BY_PROJ ||
        valueSetName === appStrings.VALUESETTYPE.LEASE_CUSTOMER_BY_UNIT ||
        valueSetName === appStrings.VALUESETTYPE.LEASE_CUSTOMER_BY_PRJ ||
        valueSetName === appStrings.VALUESETTYPE.PRJ_SECTOR ||
        valueSetName === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET ||
        valueSetName === appStrings.VALUESETTYPE.UNIT_SUB_CAT ||
        valueSetName === appStrings.VALUESETTYPE.LEASE_TOWER_LIST ||
        (valueSetName === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST &&
          this.isDependent) ||
        (valueSetName === appStrings.VALUESETTYPE.LEASE_UNIT_LIST &&
          this.isDependent) ||
        valueSetName === appStrings.VALUESETTYPE.PRJ_POCKET ||
        valueSetName === appStrings.VALUESETTYPE.INVENTORY_ORG_LIST ||
        valueSetName === appStrings.VALUESETTYPE.NFA_LOCATION ||
        valueSetName === appStrings.VALUESETTYPE.COF_CAT_BY_LE ||
        (valueSetName === appStrings.VALUESETTYPE.LOCATION &&
          this.isDependent) ||
        (valueSetName === appStrings.VALUESETTYPE.NFA_FMS_TOWER &&
          this.isDependent) ||
        (valueSetName === appStrings.VALUESETTYPE.NFA_FMS_FLOOR &&
          this.isDependent) ||
        (valueSetName === appStrings.VALUESETTYPE.NFA_FMS_UNIT &&
          this.isDependent) ||
        (valueSetName === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT &&
          this.isDependent) ||
        valueSetName === appStrings.VALUESETTYPE.NFA_SUB_CATEGORY ||
        valueSetName === appStrings.VALUESETTYPE.DESIGNATION ||
        (valueSetName === appStrings.VALUESETTYPE.POSITION &&
          this.isDependent) ||
        valueSetName === appStrings.VALUESETTYPE.TABLE_COLUMNS ||
        valueSetName === appStrings.VALUESETTYPE.WF_CRITERIA_SUB_CAT ||
        valueSetName === appStrings.VALUESETTYPE.TERM_CON_VSET ||
        valueSetName === appStrings.VALUESETTYPE.DUNNING_SETUP_SUB_TYPE ||
        valueSetName ===
          appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP_BY_TIMESHEET ||
        valueSetName ===
          appStrings.VALUESETTYPE.BILLING_PLAN_NAME_BY_TIMESHEET ||
        valueSetName ===
          appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_TIMESHEET ||
        valueSetName === appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_BY_USER ||
        valueSetName ===
          appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_BY_TIMESHEET_SUMMARY ||
        valueSetName === appStrings.VALUESETTYPE.SOURCE_TYPE ||
        valueSetName === appStrings.VALUESETTYPE.VENDOR_SITE_VSET ||
        valueSetName === appStrings.VALUESETTYPE.BILLING_PLAN ||
        valueSetName === appStrings.VALUESETTYPE.ASSET_BOOK_TYPE ||
        valueSetName === appStrings.VALUESETTYPE.TRX_TYPE) &&
        (this.parent_value_set_id === null ||
          this.parent_value_set_id === '' ||
          this.tablePrameter === null ||
          this.tablePrameter === '')) ||
        (valueSetName === appStrings.VALUESETTYPE.WF_CRITERIA_SPEC &&
          (this.catTypeForSpec === null || this.catTypeForSpec === ''))
        ? false
        : true;
    },
    alertByValuesetType(valueSetName) {
      switch (valueSetName) {
        case appStrings.VALUESETTYPE.WF_CRITERIA_SPEC:
          alert(appStrings.DEPENDENTVSETMSG.SUBCATEGORYMSG);
          break;
        case appStrings.VALUESETTYPE.WF_CRITERIA_SUB_CAT:
          alert(appStrings.DEPENDENTVSETMSG.CATEGORYMSG);
          break;
        case appStrings.VALUESETTYPE.OU_LIST:
          alert(appStrings.DEPENDENTVSETMSG.LEMSG);
          break;
        case appStrings.VALUESETTYPE.GST_OPERATING_UNIT:
          alert(appStrings.DEPENDENTVSETMSG.LEMSG);
          break;
        case appStrings.VALUESETTYPE.TABLE_COLUMNS:
          alert(appStrings.DEPENDENTVSETMSG.SYSTEMTABLESMSG);
          break;
        case appStrings.VALUESETTYPE.NFA_LOCATION:
          alert(appStrings.DEPENDENTVSETMSG.LEMSG);
          break;
        case appStrings.VALUESETTYPE.NFA_SUB_CATEGORY:
          alert(appStrings.DEPENDENTVSETMSG.CATEGORYMSG);
          break;
        case appStrings.VALUESETTYPE.DESIGNATION:
          alert(appStrings.DEPENDENTVSETMSG.BANDMSG);
          break;
        case appStrings.VALUESETTYPE.POSITION:
          alert(appStrings.DEPENDENTVSETMSG.DESIGNATIONMSG);
          break;
        case appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET:
          alert(appStrings.DEPENDENTVSETMSG.TAXGROUPMSG);
          break;
        case appStrings.VALUESETTYPE.PRJ_SECTOR:
          alert(appStrings.DEPENDENTVSETMSG.PRJMASTERPRJMSG);
          break;
        case appStrings.VALUESETTYPE.PRJ_POCKET:
          alert(appStrings.DEPENDENTVSETMSG.PRJSECTORMSG);
          break;
        case appStrings.VALUESETTYPE.PRJ_PROJECT:
          alert(appStrings.DEPENDENTVSETMSG.PRJPOCKETMSG);
          break;
        case appStrings.VALUESETTYPE.LEASE_TOWER_LIST:
          alert(appStrings.DEPENDENTVSETMSG.LEASEPRJMSG);
          break;
        case appStrings.VALUESETTYPE.LEASE_FLOOR_LIST:
          alert(appStrings.DEPENDENTVSETMSG.LEASETWRMSG);
          break;
        case appStrings.VALUESETTYPE.INVENTORY_ORG_LIST:
          alert(appStrings.DEPENDENTVSETMSG.OUMSG);
          break;
        case appStrings.VALUESETTYPE.CRM_SECTOR:
          alert(appStrings.DEPENDENTVSETMSG.CRMMASTERPRJMSG);
          break;
        case appStrings.VALUESETTYPE.CRM_POCKET:
          alert(appStrings.DEPENDENTVSETMSG.CRMSECTORMSG);
          break;
        case appStrings.VALUESETTYPE.SELECT_CRM_TOWER:
          alert(appStrings.DEPENDENTVSETMSG.CRMPROJECTMSG);
          break;
        case appStrings.VALUESETTYPE.SELECT_CRM_FLOOR:
          alert(appStrings.DEPENDENTVSETMSG.CRMTOWERMSG);
          break;
        case appStrings.VALUESETTYPE.SELECT_CRM_PROJECT:
          alert(appStrings.DEPENDENTVSETMSG.CRMPOCKETMSG);
          break;
        case appStrings.VALUESETTYPE.TERM_CON_VSET:
          alert(appStrings.DEPENDENTVSETMSG.TERMCONMSG);
          break;

        default:
          alert('Please select Parent LOV');
          break;
      }
    },
    rowSelected(item) {
      if (!this.multiSelect) {
        this.showModal = false;
        this.$emit('selectedvalueSet', item);
      }
    },
    hideModal() {
      this.showModal = false;
      this.$emit('closeValueSetModal');
      if (this.multiSelect) {
        this.$emit('selectedvalueSet', this.multiSelectList);
      }
    },
    getValueSetList() {
      this.getLOVBySetType(this.valueSetName);
    },
    clearSearh() {
      this.searchText = null;
      this.getLOVBySetType(this.valueSetName);
    },
    selectBoxChecked(flag, index, item) {
      if (flag) {
        this.multiSelectList.push(item);
      } else {
        this.multiSelectList.splice(index, 1);
      }
    }
  },
  destroyed() {
    this.eventBus.$off('valueSetCode');
  }
};
