import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import { ModelSelect } from 'vue-search-select';
import Responsibility from '../responsibility';
import Party from '../../generalLedger/party';
import Employee from '../../admin/employee';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'User',
  components: {
    Responsibility,
    ModelSelect,
    DatePicker,
    Employee,
    Party
  },
  watch: {
    currentPage: function() {
      this.getUserGridViewList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getUserGridViewList();
    }
  },
  props: { showUserModal: Boolean },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      unsubscribe: null,
      loader: false,
      payload: null,
      editMode: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      showModal: false,
      disableUserFomeFields: false,
      showResponsibilityModal: false,
      openCustomerVendorList: false,
      respTableIndex: 0,
      loginName: null,
      name: null,
      selectedUserType: {
        value: null,
        text: null
      },
      userTypeList: [
        {
          value: null,
          text: null
        }
      ],
      form: {
        loginName: null,
        user_type: null,
        user_ref_id: null,
        name: null,
        start_date: null,
        end_date: null,
        start_end_date: null,
        user_id: 0,
        responsibility_desc: null
      },
      data: [],
      fields: [
        {
          key: 'user_email',
          label: 'Login Name'
        },
        {
          key: 'user_type_meaning',
          label: 'User Type'
        },
        {
          key: 'user_code'
        },
        {
          key: 'name'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        }
      ],
      responsibilityData: [
        {
          responsibility_hdr_id: 0,
          user_res_mpng_id: 0,
          responsibility_name: null,
          responsibility_desc: null,
          start_date: null,
          end_date: null
        }
      ],
      responsibilityDataFields: [
        {
          key: 'responsibility_name'
        },
        {
          key: 'responsibility_desc',
          label: 'Responsibility Description'
        },
        {
          key: 'start_date_end_date',
          label: 'Start Date - End Date'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    if (this.showUserModal) {
      this.getUserGridViewList();
    }
    this.getUserTypeLov();
    this.registerEventBus();
  },
  methods: {
    registerEventBus() {
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'shared/setActionName') {
          const actionName = state.shared.actionName;
          if (actionName === 'add') {
            this.resetUserForm();
          }
          if (actionName === 'edit' || actionName === 'update') {
            this.editMode = true;
            this.update = true;
            this.disableUserFomeFields = true;
          }
          if (actionName === 'save') {
            if (this.showModal) {
              this.addEditUser();
              this.getUserGridViewList();
            }
          }
          if (actionName === 'download' && !this.showModal) {
            this.loader = true;
            /**
             * @param(payload, 'action name', 'file name')
             */
            const downloadpayload = { ...this.payload };
            downloadpayload._limit = this.totalRows;
            this.downloadExcel.downloadData(
              downloadpayload,
              'defineUser/getUserGridViewList',
              'user',
              () => (this.loader = false)
            );
          }
          if (actionName === 'upload' && this.showModal) {
            this.eventBus.$emit('commonUpload', { id: this.form.user_id });
          }
        }
      });
    },
    getUserTypeLov() {
      this.$store
        .dispatch(
          'shared/getDataFromLookupsByLookupType',
          appStrings.LOOKUPTYPE.USER_TYPE
        )
        .then(response => {
          if (response.data.data) {
            const result = response.data.data;
            const userArr = result.map(type => {
              return {
                value: type.lookup_code,
                text: type.meaning
              };
            });
            this.userTypeList = userArr;
          }
        });
    },
    getUserGridViewList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        login_name: this.loginName,
        name: this.name,
        user_type: this.selectedUserType.value
      };
      this.loader = true;
      this.$store
        .dispatch('defineUser/getUserGridViewList', this.payload)
        .then(response => {
          this.loader = false;
          this.data = response.data.data.page;
          this.totalRows = response.data.data.total_elements;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(rowData) {
      if (this.showUserModal) {
        this.$emit('selectedUser', rowData);
      } else {
        this.showModal = true;
        this.disableUserFomeFields = true;
        this.editMode = false;
        this.form.loginName = rowData.user_email;
        this.form.name = rowData.name;
        this.form.user_type = rowData.user_type;
        this.form.start_end_date = [
          new Date(rowData.start_date),
          new Date(rowData.end_date)
        ];
        this.form.start_date = rowData.start_date;
        this.form.end_date = rowData.end_date;
        this.form.user_ref_id = rowData.user_ref_id;
        this.form.user_id = rowData.user_id;
        this.getUserResponsibilityList(rowData.user_id);
        this.showAlert = false;
      }
    },
    getUserResponsibilityList(user_id) {
      this.loader = true;
      this.$store
        .dispatch('defineUser/getUserResponsibilityList', user_id)
        .then(response => {
          this.loader = false;
          if (response.data.data) {
            const result = response.data.data;
            if (result.user_responsibilities.length > 0) {
              this.responsibilityData = result.user_responsibilities.map(
                data => {
                  data.start_date_end_date = [
                    new Date(data.start_date),
                    new Date(data.end_date)
                  ];
                  return data;
                }
              );
            }
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
        });
    },
    addEditUser() {
      const payload = {
        user_id: this.form.user_id,
        email_id: this.form.loginName,
        user_ref_id: this.form.user_ref_id,
        user_type: this.form.user_type,
        start_date: commonHelper.formattedDate(this.form.start_end_date[0]),
        end_date: commonHelper.formattedDate(this.form.start_end_date[1])
      };
      this.loader = true;
      this.$store
        .dispatch('defineUser/addEditUser', payload)
        .then(response => {
          this.showAlert = true;
          this.loader = false;
          if (response.status === 200) {
            this.form.user_id = response.data.data.user_id;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            if (this.responsibilityData[0].responsibility_hdr_id !== 0) {
              this.addEditUserResponsibility();
            }
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    addEditUserResponsibility() {
      const details = this.responsibilityData.map(responsibilityData => {
        return {
          user_res_mpng_id: responsibilityData.user_res_mpng_id,
          responsibility_hdr_id: responsibilityData.responsibility_hdr_id,
          start_date: commonHelper.formattedDate(
            responsibilityData.start_date_end_date[0]
          ),
          end_date: commonHelper.formattedDate(
            responsibilityData.start_date_end_date[1]
          )
        };
      });
      const payload = {
        user_id: this.form.user_id,
        responsibility: {
          responsibility_details: details ? details : null
        }
      };
      this.$store
        .dispatch('defineUser/addEditUserResponsibility', payload)
        .then(response => {
          this.editMode = false;
          if (response.status === 200) {
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    addNewRow() {
      if (this.editMode) {
        this.responsibilityData.push({
          responsibility_name: null,
          responsibility_desc: null,
          start_date: null,
          end_date: null,
          start_date_end_date: null,
          responsibility_hdr_id: 0,
          user_res_mpng_id: 0
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.responsibilityData.splice(index, 1);
      }
    },
    enableInputs(index, commIndex) {
      this.detailIndex = index;
      this.responseIndex = commIndex;
    },
    openResponsibilityList(index) {
      if (this.editMode) {
        this.respTableIndex = index;
        if (
          this.responsibilityData[this.respTableIndex].user_res_mpng_id == 0
        ) {
          this.showResponsibilityModal = true;
        }
      }
    },
    selectedResponsibility(respData) {
      this.responsibilityData[this.respTableIndex].responsibility_name =
        respData.responsibility_name;
      this.responsibilityData[this.respTableIndex].responsibility_desc =
        respData.responsibility_desc;
      this.responsibilityData[this.respTableIndex].responsibility_hdr_id =
        respData.responsibility_hdr_id;
      this.showResponsibilityModal = false;
    },
    openSearchUserName() {
      if (this.editMode && this.form.user_type) {
        this.openCustomerVendorList = true;
        setTimeout(() => {
          this.eventBus.$emit('userType', this.form.user_type);
        }, 0);
      } else {
        alert('Please select User Type');
      }
    },
    selectedUserName(userData) {
      if (
        this.form.user_type === 'CUST' ||
        this.form.user_type === 'VEND' ||
        this.form.user_type === 'PARTY'
      ) {
        this.form.name = userData.party_name;
        if (this.form.user_type === 'CUST') {
          this.getCustIdByPartyId(userData.party_id);
        }
        if (this.form.user_type === 'VEND') {
          this.getVendorIdByPartyId(userData.party_id);
        }
        if (this.form.user_type === 'PARTY') {
          this.form.user_ref_id = userData.party_id;
        }
      } else {
        this.form.name = userData.name;
        this.form.user_ref_id = userData.employee_id;
      }
      this.openCustomerVendorList = false;
    },
    getCustIdByPartyId(party_id) {
      this.$store
        .dispatch('defineUser/getCustIdByPartyId', party_id)
        .then(response => {
          this.form.user_ref_id = response.data.data;
        });
    },
    getVendorIdByPartyId(party_id) {
      this.$store
        .dispatch('defineUser/getVendorIdByPartyId', party_id)
        .then(response => {
          this.form.user_ref_id = response.data.data;
        });
    },
    resetUserForm() {
      this.disableUserFomeFields = false;
      this.showAlert = false;
      this.showModal = true;
      this.editMode = true;
      this.form.loginName = null;
      this.form.name = null;
      this.form.user_type = null;
      this.form.start_date = null;
      this.form.end_Date = null;
      this.form.start_end_date = null;
      this.form.user_id = 0;
      this.form.user_ref_id = 0;
      this.responsibilityData = [
        {
          responsibility_name: null,
          responsibility_desc: null,
          start_date: null,
          end_date: null,
          responsibility_hdr_id: 0,
          user_res_mpng_id: 0
        }
      ];
    },
    setDate(dateRange) {
      this.form.start_end_date = commonHelper.setFutureDate(dateRange);
    },
    clearFilter() {
      this.loginName = null;
      this.name = null;
      this.selectedUserType = {
        value: null,
        text: null
      };
      this.data = [];
      this.totalRows = null;
      this.currentPage = 1;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};