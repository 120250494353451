import http from '../../app/services/httpServices';
import URL_UTILITY from '../../app/utility/url.utility';

const getInvItemsGridList = (context, payload) => {
  return http.getApi(URL_UTILITY.getInvItemsGridUrl, payload);
};

const getMappingInvItemById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getInvItemsGridUrl +
      '/' +
      payload.inventoryItemId +
      '/map?org_name=' +
      payload.org_name
  );
};

const getInventoryItemDetails = (context, payload) => {
  return http.getApi(URL_UTILITY.getInvItemsGridUrl + '/master-items', payload);
};

const addEditMappingMaintainLots = (context, payload) => {
  return http.postApi(URL_UTILITY.getInvMaintainLot, payload);
};

const getInventoryMaintainLot = (context, payload) => {
  return http.getApi(URL_UTILITY.getInvMaintainLot, payload);
};

const getInventoryItemCode = () => {
  return http.getApi(URL_UTILITY.getInvItemsGridUrl + '/item-codes');
};
const getInventoryParentLots = () => {
  return http.getApi(URL_UTILITY.getInvMaintainLot + '/patent-lots');
};

const addEditInventoryItems = (context, payload) => {
  return http.postApi(URL_UTILITY.getInvItemsGridUrl, payload);
};
const addEditMappingInvItemsById = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getInvItemsGridUrl + '/' + payload.inventory_item_id + '/map',
    payload.mappingItems
  );
};
const getAssignCategoryList = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getInvItemsGridUrl + '/assign-categories',
    payload
  );
};
const addEditAssignCategory = (context, payload) => {
  return http.postApi(URL_UTILITY.getInvItemsGridUrl, payload);
};
export default {
  getInvItemsGridList,
  getMappingInvItemById,
  getInventoryItemDetails,
  addEditInventoryItems,
  addEditMappingInvItemsById,
  getAssignCategoryList,
  addEditAssignCategory,
  getInventoryMaintainLot,
  getInventoryItemCode,
  getInventoryParentLots,
  addEditMappingMaintainLots
};
