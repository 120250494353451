import store from '../../../../../../store/';
export default {
  name: 'customerSiteContact',
  data() {
    return {
      unsubscribe: null,
      partyProfileType: '',
      editMode: false,
      vendorId: '',
      customerSiteContactId: 0,
      partyContactPersonId: null,
      showPartyContactCommunicationModel: false,
      customerSiteIndex: 0,
      customerId: '',
      customerSiteList: [],
      showPartyContactListModel: false,
      contactDetailIndex: null,
      communicationIndex: null,
      partyId: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      openCommunicationModal: false,
      contactPurpose: null,
      contactName: null,
      isActive: false,
      primaryContact: 'No',
      createdBy: null,
      creationDate: null,
      lastDateUpdate: null,
      lastUpdateBy: null,
      customerSiteId: null,
      partyContactDetails: [],
      contactDetailFields: [
        {
          key: 'communication'
        },

        {
          key: 'contact_purpose'
        },
        {
          key: 'contact_name'
        },
        {
          key: 'active'
        },
        {
          key: 'primary_contact'
        },
        {
          key: 'party_contact_person_id',
          class: 'd-none'
        },
        {
          key: 'customer_site_contact_id',
          class: 'd-none'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'last_update_date'
        },
        {
          key: 'last_updated_by'
        }
      ],
      communicationListFieldsList: [
        {
          key: 'contact_type'
        },
        {
          key: 'comm_data'
        },
        {
          key: 'active'
        },
        {
          key: 'primary_comm'
        }
      ],
      partyContactList: [],
      partyContactFields: [
        {
          key: 'salutation'
        },
        {
          key: 'first_name'
        },
        {
          key: 'middle_name'
        },
        {
          key: 'last_name'
        },
        {
          key: 'contact_purpose'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'active'
        },
        {
          key: 'party_contact_person_id',
          class: 'd-none'
        }
      ],
      partyContactCommList: [],
      partyContactCommFields: [
        {
          key: 'comm_type'
        },
        {
          key: 'area_code'
        },
        {
          key: 'country_code'
        },
        {
          key: 'email'
        },
        {
          key: 'party_contact_comm_id',
          class: 'd-none'
        }
      ],
      communicationListData: [],
      selectedRowDetails: null,
      contactId: null,
      responseMessage:'',
      showAlerts:false,
      isSuccesss:false
    };
  },
  mounted() {
    this.eventBus.$off('customerSiteContact');
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (store.state.party.customerTabValue === 'customerSiteContact') {
          if (actionName === 'add') {
            this.showHideModels();
          }
          if (actionName === 'update') {
            this.editMode = true;
          }
          if (actionName === 'save') {
            this.postSiteContactDetails();
          }
        }
      }
    });
    this.eventBus.$on('customerSiteContact', customerSite => {
      this.getPartyContacts();
      this.partyProfileType = store.state.party.partyProfile;
      if (store.state.party.partyProfile === 'customer') {
        this.customerId = customerSite.customer_id;
        this.getCustomerSiteContactByPartyId();
      } else if (store.state.party.partyProfile === 'vendor') {
        this.vendorId = customerSite.vendor_id;
        this.getVendorcontactList();
      }
    });
  },
  methods: {
    showHideModels() {
      // if (this.partyContactPersonId !== null) {
      //   this.showPartyContactCommunicationModel = true;
      // } else if (this.partyContactPersonId === null) {
      //   this.showPartyContactListModel = true;
      // }
      if (this.openCommunicationModal) {
        this.showPartyContactCommunicationModel = true;
      } else {
        this.showPartyContactListModel = true;
      }
    },
    getCustomerSiteContactByPartyId() {
      const payload = {
        partyId: store.state.party.partyId
      };
      this.loader = true;
      this.$store
        .dispatch('party/getCustomerSiteContactByPartyId', payload)
        .then(response => {
          this.customerSiteList = response.data.data;
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getPartyContacts() {
      const partyId = store.state.party.partyId;
      this.$store
        .dispatch('party/getPartyContactsByPartyId', partyId)
        .then(response => {
          this.partyContactList = response.data.data;
        });
    },
    closePartyCustomerModel() {
      this.showPartyContactListModel = false;
    },
    closePartyCustomerCommModel() {
      this.showPartyContactCommunicationModel = false;
      this.getCustomerContact(this.contactId);
    },
    selectedContactRow(item) {
      if (this.partyProfileType === 'customer') {
        const payload = {
          customerId: this.customerId,
          contactpayload: {
            customer_site_contacts: [
              {
                customer_site_id: this.customerSiteList[this.customerSiteIndex]
                  .customer_site_id,
                customer_site_contact_id: 0,
                customer_site_contact_comm_id: null,
                party_contact_person_id: item.party_contact_person_id,
                party_contact_comm_id: null,
                comm_active: false,
                contact_active: true,
                primary_comm: false,
                primary_contact: true
              }
            ]
          }
        };
        this.loader = true;
        this.$store
          .dispatch('party/getAddEditCustomerSiteContact', payload)
          .then(response => {
            this.getCustomerSiteContactByPartyId();
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.showPartyContactListModel = false;
            this.customerSiteList = response.data.data.customer_sites;
          });
      } else if (this.partyProfileType === 'vendor') {
        let payload = {};
        payload = {
          vendorId: this.vendorId,
          contactData: {
            vendor_site_contacts: [
              {
                vendor_site_id: this.customerSiteList[this.customerSiteIndex]
                  .vendor_site_id,
                vendor_site_contact_id: 0,
                vendor_site_contact_comm_id: null,
                party_contact_person_id: item.party_contact_person_id,
                party_contact_comm_id: null,
                comm_active: false,
                contact_active: true,
                primary_comm: false,
                primary_contact: true
              }
            ]
          }
        };
        this.loader = true;
        this.$store
          .dispatch('party/addEditVendorContact', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.showPartyContactListModel = false;
            this.customerSiteList = response.data.data.vendor_sites_contact;
          });
      }
    },
    selectedContactCommRow(item) {
      if (this.partyProfileType === 'customer') {
        const payload = {
          customerId: this.customerId,
          contactpayload: {
            customer_site_contacts: [
              {
                customer_site_id: this.customerSiteList[this.customerSiteIndex]
                  .customer_site_id,
                customer_site_contact_id: this.customerSiteContactId,
                customer_site_contact_comm_id: 0,
                party_contact_person_id: this.partyContactPersonId,
                party_contact_comm_id: item.party_contact_comm_id,
                comm_active: true,
                contact_active: true,
                primary_comm: false,
                primary_contact: true
              }
            ]
          }
        };
        this.loader = true;
        this.$store
          .dispatch('party/getAddEditCustomerSiteContact', payload)
          .then(response => {
            this.partyContactPersonId = null;
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.showPartyContactListModel = false;
            this.customerSiteList = response.data.data.customer_sites;
            this.showPartyContactCommunicationModel = false;
            this.getCustomerSiteContactByPartyId();
          });
      } else if (this.partyProfileType === 'vendor') {
        let payload = {};
        payload = {
          vendorId: this.vendorId,
          contactData: {
            vendor_site_contacts: [
              {
                vendor_site_id: this.customerSiteList[this.customerSiteIndex]
                  .vendor_site_id,
                vendor_site_contact_id: this.customerSiteContactId,
                vendor_site_contact_comm_id: 0,
                party_contact_person_id: this.partyContactPersonId,
                party_contact_comm_id: item.party_contact_comm_id,
                comm_active: false,
                contact_active: true,
                primary_comm: false,
                primary_contact: true
              }
            ]
          }
        };
        this.loader = true;
        this.$store
          .dispatch('party/addEditVendorContact', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.showPartyContactListModel = false;
            this.showPartyContactCommunicationModel = false;
            this.customerSiteList = response.data.data.vendor_sites_contact;
          });
      }
    },
    selectedSite(index) {
      this.customerSiteIndex = index;
    },
    selectContactRow(contactPersonId, customerSiteContactId, isShowDetails) {
      this.customerSiteContactId = customerSiteContactId;
      this.partyContactPersonId = isShowDetails ? contactPersonId : null;
      this.partyContactCommList = this.partyContactList.find(
        contact => contact.party_contact_person_id === contactPersonId
      ).communication_details;
    },
    getVendorcontactList() {
      this.loader = true;
      this.$store
        .dispatch('party/getVendorContacts', this.vendorId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.customerSiteList = response.data.data.vendor_sites_contact;
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    openCustomerSiteContact(contact_id) {
      this.editMode = false;
      this.contactId = contact_id;
      this.getCustomerContact(contact_id);
      this.openCommunicationModal = true;
      this.showAlert=false;
    },
    closeCommunicationModal(flag) {
      this.openCommunicationModal = flag;
      this.showAlerts=false;
      this.showAlert=false;
      this.getCustomerSiteContactByPartyId()
    },
    parentActiveChange(activeValue) {
      for (let i of this.communicationListData) {
        if (activeValue === false) {
          i.active = false;
        }
      }
    },
    postSiteContactDetails() {
      let customersitecontact = [];
      for (let i of this.communicationListData) {
        customersitecontact.push({
          customer_site_contact_id: i.customer_site_contact_id,
          party_contact_comm_id: i.party_contact_comm_id,
          customer_site_contact_comm_id: i.customer_site_contact_comm_id,
          primary_comm: i.primary_comm,
          comm_active: i.active,
          customer_site_id: this.customerSiteId,
          party_contact_person_id: this.partyContactPersonId,
          contact_active: this.isActive,
          primary_contact: this.primaryContact == 'Yes' ? true : false
        });
      }
      const payload = {
        customer_site_contacts: customersitecontact
      };
      const pay = {
        customerId: this.customerId,
        payload: payload
      };
      this.loader = true;
      this.showAlerts = true;
      this.$store
        .dispatch('party/getAddEditCustomerSiteContactNew', pay)
        .then(response => {
          if (response.status === 200) {
            this.loader = false;
            this.isSuccesss = true;
            this.responseMessage = response.data.message;
          } else {
            this.isSuccesss = false;
            this.responseMessage = response.data.message;
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    getCustomerContact(contact_id) {
      const payload = {
        contact_id: contact_id
      };
      this.loader = true;
      this.$store
        .dispatch('party/getCustomerContact', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
           let result = response.data.data;
            this.contactPurpose = result.contact_purpose;
            this.contactName = result.contact_name;
            this.primaryContact = result.primary_contact ? 'Yes' : 'No';
            this.createdBy = result.created_by;
            this.creationDate = result.creation_date;
            this.lastDateUpdate = result.last_update_date;
            this.lastUpdateBy = result.last_updated_by;
            this.isActive = result.active;
            this.communicationListData = result.customer_site_contact_comms;
            this.partyContactPersonId = result.party_contact_person_id;
            this.customerSiteId = result.customer_site_id;
                              
            
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    }
  },

  beforeDestroy() {
    this.unsubscribe();
  }
};
