import { BasicSelect, ModelSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import ViewEditParty from './viewEditParty';
import CreateParty from './createParty';
import PartyCustomer from './partyCustomer/';
import PartyVendor from './partyVendor/';
import CreateBranch from './partyBank/createBranch/';
import PartyBank from './partyBank/';

export default {
  name: 'party',
  components: {
    BasicSelect,
    ModelSelect,
    ViewEditParty,
    CreateParty,
    PartyCustomer,
    PartyVendor,
    CreateBranch,
    PartyBank
  },
  watch: {
    currentPage: function() {
      this.searchPartyList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.searchPartyList();
    }
  },
  data() {
    return {
      unsubscribe: null,
      userType: null,
      showHideViewEditParty: false,
      isPartyBankEnable: false,
      partyIndex: null,
      payload: {},
      showCreateBranch: false,
      showBranch: '',
      showParty: '',
      isPartyCustomerEnable: false,
      isPartyVendorEnable: false,
      showCreateParty: false,
      partyId: null,
      showPartyDetails: false,
      partyTypeDataResponse: [],
      loader: false,
      showHideParty: true,
      partyName: '',
      panNum: '',
      shortName: '',
      customerType: '',
      lovType: appStrings.VALUESETTYPE.PARTY_TYPE,
      patyTypeArr: [
        {
          value: null,
          text: null
        }
      ],
      selectedParty: {
        value: null,
        text: null
      },
      partyCategory: [
        {
          value: null,
          text: null
        }
      ],
      selectedCateory: {
        value: null,
        text: null
      },

      search: '',
      selected: '',
      data: [],
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      fields: [
        {
          key: 'party_type'
        },
        {
          key: 'party_category'
        },
        {
          key: 'party_id'
        },
        {
          key: 'party_name'
        },
        {
          key: 'short_name'
        },
        {
          key: 'pan_no',
          label: 'PAN No'
        },
        {
          key: 'active'
        },
        {
          key: 'is_customer',
          label: 'Customer'
        },
        {
          key: 'is_vendor',
          label: 'Vendor'
        },
        {
          key: 'is_bank',
          label: 'Bank'
        },
        {
          key: 'history'
        }
      ],
      customerPartyId: null,
      glActive: {
        value: null,
        text: null
      },
      showValueSetModal: false,
      vendorId: null,
      showPartyHistoryModal: false,
      partyHistoryData: [],
      partyHistoryFields: [
        {
          key: 'created_by'
        },

        {
          key: 'creation_date'
        },
        {
          key: 'last_update_date'
        },
        {
          key: 'last_updated_by'
        }
      ],     
      showExcelUploadModal: false
    };
  },
  mounted() {
    this.eventBus.$off('closeCustomer');
    this.eventBus.$off('viewEditParty');
    this.eventBus.$off('userType');
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        this.eventBus.$emit('viewEditParty', actionName);
        if (actionName === 'edit' || actionName === 'update') {
          this.editMode = true;
          this.update = true;
        }
        if (
          actionName === 'download' &&
          !this.showCreateParty &&
          !this.showHideViewEditParty &&
          !this.isPartyCustomerEnable &&
          !this.isPartyVendorEnable &&
          !this.isPartyBankEnable &&
          !this.showCreateBranch
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'party/getPartyList',
            'party',
            () => (this.loader = false)
          );
        }
        if (
          actionName === 'add' &&
          this.partyId === null &&
          !this.isPartyCustomerEnable
        ) {
          this.showCreateParty = true;
          setTimeout(() => (this.showParty = 'show'), 0);
        }
        if (actionName === 'upload') {
          this.showExcelUploadModal = true;
        }
      }
    });
    this.eventBus.$on('closeCustomer', flag => {
      this.$store.dispatch('party/setUnsetPartyId', null);
      this.isPartyCustomerEnable = flag;
      this.isPartyVendorEnable = false;
      this.showHideParty = true;
    });
    this.eventBus.$on('userType', userType => {
      this.userType = userType;
      if (userType === 'CUST') {
        this.customerType = 'CUSTOMER';
      } else if (userType === 'VEND') {
        this.customerType = 'VENDOR';
      }
    });
  },
  methods: {
    getLOVBySetType() {
      this.data = [];
      this.loader = true;
      const findPartyObj =
        this.partyTypeDataResponse &&
        this.partyTypeDataResponse.find(
          partyObj => partyObj.value_code === this.selectedParty.value
        );
      const payload = {
        lovType: this.lovType,
        parent_value_set_id: findPartyObj ? findPartyObj.value_set_dtl_id : null
      };
      this.$store
        .dispatch('party/getLOVBySetType', payload)
        .then(response => {
          this.loader = false;
          if (response.data.data) {
            const result = response.data.data;
            const partyTypeData = result.map(type => {
              if (this.lovType === appStrings.VALUESETTYPE.PARTY_TYPE) {
                return {
                  value: type.value_code,
                  text: type.value_meaning
                };
              } else if (this.lovType === appStrings.VALUESETTYPE.PARTY_CAT) {
                return {
                  value: type.value_code,
                  text: type.value_meaning
                };
              }
            });
            if (this.lovType === appStrings.VALUESETTYPE.PARTY_TYPE) {
              this.partyTypeDataResponse = response.data.data;
              this.patyTypeArr = partyTypeData;
            } else if (this.lovType === appStrings.VALUESETTYPE.PARTY_CAT) {
              this.partyCategory = partyTypeData;
            }
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    selectedPartyTypeFun(val) {
      this.lovType = appStrings.VALUESETTYPE.PARTY_CAT;
      this.selectedParty = val;
      this.getLOVBySetType();
    },
    searchPartyList() {
      this.loader = true;
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        party_type_vset: this.selectedParty.value,
        party_cat_vset: this.selectedCateory.value,
        short_name: this.shortName,
        party_name: this.partyName,
        pan_no: this.panNum,
        type: this.customerType,
        active: this.glActive.value
      };
      this.$store
        .dispatch('party/getPartyList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.data = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
            this.pageOptions = commonHelper
              .getPageOption()
              .filter((pageCount, index) => {
                if (
                  pageCount <= this.totalRows ||
                  commonHelper.getPageOption()[index - 1] < this.totalRows
                ) {
                  return pageCount;
                }
              });
          }
          2;
        })
        .catch(error => {
          alert(error.message);
          this.loader = false;
        });
    },
    showHideCreateParty() {
      this.showParty = 'hide';
      setTimeout(() => (this.showCreateParty = false), 500);
      this.partyId = null;
    },
    rowSelected(item) {
      this.showHideParty = false;
      this.showHideViewEditParty = true;
      this.$store.dispatch('party/setUnsetPartyName', item.party_name);
      this.$store.dispatch('party/setUnsetPartyType', item.party_type);
      this.$store.dispatch('party/setUnsetPartyShortName', item.short_name);
      setTimeout(() => {
        this.showPartyDetails = true;
        this.partyId = item.party_id;
      });
      item.party_type = this.customerType;
      this.$emit('selectedUserName', item);
    },
    showHidePartyView() {
      this.searchPartyList();
      this.showHideParty = true;
      this.showPartyDetails = false;
      this.showHideViewEditParty = false;
      this.partyId = null;
    },
    createPartyCustomer(
      partyId,
      isCustomerCreated,
      index,
      partyName,
      partyType,
      shortName
    ) {
      this.customerPartyId = partyId;
      this.$store.dispatch('party/setUnsetPartyName', partyName);
      this.$store.dispatch('party/setUnsetPartyType', partyType);
      this.$store.dispatch('party/setUnsetPartyShortName', shortName);
      this.$store.dispatch('party/setPartyProfile', 'customer');
      const payload = {
        partyId: partyId,
        isCustomerCreated: isCustomerCreated
      };
      this.loader = true;
      this.$store.dispatch('party/setUnsetPartyId', partyId);
      if (isCustomerCreated) {
        this.$store
          .dispatch('party/createPartyCustomer', payload)
          .then(response => {
            this.isPartyCustomerEnable = true;
            this.showHideParty = false;
            this.loader = false;
            if (response.status === 200) {
              setTimeout(() => {
                this.eventBus.$emit('partyCustomerData', response.data.data);
              }, 500);
            }
          })
          .catch(error => {
            this.loader = false;
            alert(error.message);
          });
      } else {
        this.$store
          .dispatch('party/createPartyCustomer', payload)
          .then(response => {
            this.data[index].is_customer = true;
            this.loader = false;
            if (response.status === 200) {
              this.isPartyCustomerEnable = true;
              this.showHideParty = false;
              setTimeout(() => {
                this.eventBus.$emit('partyCustomerData', response.data.data);
              }, 500);
            }
          })
          .catch(error => {
            this.loader = false;
            alert(error.message);
          });
      }
    },
    createPartyBank(
      partyId,
      isBankCreated,
      index,
      partyName,
      partyType,
      shortName
    ) {
      this.$store.dispatch('party/setUnsetPartyName', partyName);
      this.$store.dispatch('party/setUnsetPartyType', partyType);
      this.$store.dispatch('party/setUnsetPartyShortName', shortName);
      this.$store.dispatch('party/setPartyProfile', 'bank');
      this.$store.dispatch('party/setUnsetPartyId', partyId);
      this.partyIndex = index;
      if (isBankCreated) {
        this.isPartyBankEnable = true;
        this.showHideParty = false;
      } else {
        this.showCreateBranch = true;
        setTimeout(() => (this.showBranch = 'show'), 0);
      }
    },
    createPartyVendor(
      partyId,
      isVendorCreated,
      index,
      partyName,
      partyType,
      shortName
    ) {
      this.$store.dispatch('party/setUnsetPartyName', partyName);
      this.$store.dispatch('party/setUnsetPartyType', partyType);
      this.$store.dispatch('party/setUnsetPartyShortName', shortName);
      this.$store.dispatch('party/setPartyProfile', 'vendor');
      this.$store.dispatch('party/setUnsetPartyId', partyId);
      if (isVendorCreated) {
        this.loader = true;
        this.getPartyVendorDetails(partyId);
      } else {
        this.loader = true;
        this.$store
          .dispatch('party/createPartyVendor', partyId)
          .then(response => {
            this.loader = false;
            this.data[index].is_vendor = true;
            if (response.status === 200) {
              this.getPartyVendorDetails(partyId);
            } else if (response.response.data.status === 400) {
              this.isPartyVendorEnable = true;
              this.showHideParty = false;
            }
          })
          .catch(error => {
            this.loader = false;
            alert(error.message);
          });
      }
    },
    bankBranchCreated() {
      this.data[this.partyIndex].is_bank = true;
    },
    showHideCreateBranch() {
      this.showBranch = 'hide';
    },
    closePartyBankTabs() {
      this.isPartyBankEnable = false;
      this.showHideParty = true;
      this.$store.dispatch('party/setPartyProfile', null);
      this.$store.dispatch('party/setUnsetPartyId', null);
    },
    getPartyVendorDetails(partyId) {
      this.$store
        .dispatch('party/getPartyVenderSiteList', partyId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.isPartyVendorEnable = true;
            this.showHideParty = false;
            this.vendorId = response.data.data.vendor_id;
            setTimeout(() => {
              this.eventBus.$emit('partyVendorData', response.data.data);
            }, 500);
          }
        });
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.GL_ACTIVE_VSET) {
        this.glActive.text = item.value_meaning;
        this.glActive.value = item.value_code;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    getPartyHistorybyPartyId(partyId) {
      this.loader = true;
      this.$store
        .dispatch('party/getPartyHistorybyPartyId', partyId)
        .then(response => {
          this.partyHistoryData = [response.data.data];
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openPartyHistoryModal(partyId) {
      this.getPartyHistorybyPartyId(partyId);
      this.showPartyHistoryModal = true;
    },
    showHidePartyHistoryModal(flag) {
      this.showPartyHistoryModal = flag;
    },
    clearSearch() {
      this.totalRows = null;
      this.currentPage = 1;
      this.selectedCateory = {
        value: null,
        text: null
      };
      this.selectedParty = { value: null, text: null };
      this.partyCategory = [
        {
          value: null,
          text: null
        }
      ];
      this.partyName = null;
      this.shortName = null;
      this.panNum = null;
      this.customerType = null;
      this.data = [];
      this.glActive = {
        value: null,
        text: null
      };
    }
  },
  created() {
    this.getLOVBySetType();
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
