import { ModelSelect, BasicSelect } from 'vue-search-select';
import { required } from 'vuelidate/lib/validators';
import store from '../../../../store/';
import Address from '../address/';
import { format } from 'date-fns';
import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import FlexField from '../flexfield/';

export default {
  name: 'Organization',
  props: {
    showModalState: Boolean,
    formState: String
  },
  components: {
    ModelSelect,
    BasicSelect,
    Address,
    DatePicker,
    FlexField
  },
  watch: {
    currentPage: function() {
      this.getOrganizationList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getOrganizationList();
    },
    'organizationForm.org_type': function() {
      if (this.organizationForm.org_type.value !== 'LE') {
        this.organizationForm.slectedOrgLegalType = {
          value: null,
          text: null
        };
      }
      if (
        this.organizationForm.org_type.value === 'GROUP' ||
        this.organizationForm.org_type.value === 'LOC'
      ) {
        this.organizationForm.slectedOrgParentName = {
          value: null,
          text: null
        };
      }
    }
  },
  data() {
    return {
      unsubscribe: null,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      isCurrentPath: false,
      showFlexFieldModal: false,
      orgSpecCatModal: false,
      orgSpecModal: false,
      showAddAddress: false,
      loader: false,
      orgType: '',
      orgShortName: '',
      orgName: '',
      parentOrgName: '',
      orgNameSearch: '',
      shortNameSearch: '',
      parentOrgSearch: '',
      data: [],
      totalRows: null,
      perPage: 5,
      currentPage: 1,
      pageOptions: [5, 10, 15, 100],
      defaultOrgTypeCode: '',
      orgTypeData: [],
      organizationListData: [],
      filterOn: [],
      showModal: false,
      editMode: false,
      organizationList: [],
      addressTypeList: [],
      orgLegalTypeList: [],
      orgParentNameList: [],
      payload: null,
      organizationForm: {
        org_type: {
          value: '',
          text: ''
        },
        selectedAddressType: {
          value: null,
          text: null
        },
        slectedOrgLegalType: {
          value: null,
          text: null
        },
        slectedOrgParentName: {
          value: null,
          text: null
        },
        org_name: '',
        org_description: null,
        org_short_name: '',
        parent_name: '',
        address_name: '',
        address: '',
        address_id: 0,
        erp_org_id: 0,
        org_id: 0,
        hrms_flex_field_id: 0,
        hrms_flex_field_name: null,
        org_parent_id: 0,
        emp_num_auto_lpad: null,
        employee_num_prefix: null
      },
      stickyHeader: true,
      fields: [
        {
          key: 'org_type',
          sortable: false
        },
        {
          key: 'org_name',
          sortable: true
        },
        {
          key: 'org_short_name',
          sortable: false
        },
        {
          key: 'parent_name',
          sortable: false
        },
        {
          key: 'org_description',
          sortable: false
        },
        {
          key: 'address_name',
          sortable: false
        },
        {
          key: 'address',
          sortable: false
        },
        {
          key: 'org_id',
          class: 'd-none'
        }
      ],
      orgSpecCatFields: [
        {
          key: 'value',
          label: 'Category Code'
        },
        {
          key: 'text',
          label: 'Category'
        }
      ],
      orgSpecCatData: [],
      categoryBasedSpecData: [],
      categoryBasedSpecFields: [
        {
          key: 'text'
        },
        {
          key: 'value'
        }
      ],
      apiOrgSpecCatList: [],
      orgGeneralSpecList: [
        {
          org_spec_id: 0,
          org_spec_cat: null,
          org_spec: null,
          org_spec_detail: null,
          start_date: format(new Date(), 'dd-MMM-yyyy'),
          end_date: null,
          start_date_end_date: [],
          selectedOrgSpecCat: {
            value: null,
            text: null
          },
          orgSpecLookupList: [],
          orgSelectedSpec: {
            value: null,
            text: null
          }
        }
      ],
      orgSpecField: [
        {
          key: 'org_spec_cat'
        },
        {
          key: 'org_spec'
        },
        {
          key: 'org_spec_detail'
        },
        {
          key: 'start_date_end_date',
          label: 'Start Date - End Date'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  validations: {
    organizationForm: {
      orgType: {
        value: {
          required
        },
        text: {
          required
        }
      },
      orgName: {
        required
      },
      orgDesc: {
        required
      },
      orgSName: {
        required
      },
      orgLStatus: {
        required
      },
      orgPName: {
        required
      },
      orgErpName: {
        required
      },
      empNoPrefix: {
        required
      },
      empNoLPAD: {
        required
      },
      hrmsFId: {
        required
      },
      orgAddress: {
        required
      }
    }
  },
  mounted() {
    this.getOrganizationType();
    this.getOrganizationList();
    this.getSpecCategory();
    this.getOrgAddressType();
    this.getOrgLegalType();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'edit' || actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'add') {
          this.showModal = true;
          this.editMode = true;
        }
        if (actionName === 'save' && this.showModal && this.editMode) {
          if (!this.showAddAddress) {
            this.addEditOrganization();
          }
        }
        if (actionName === 'download' && !this.showModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'organization/getOrganizationList',
            'organization',
            () => (this.loader = false)
          );
        }
        if (actionName === 'upload' && this.showModal) {
          this.eventBus.$emit('commonUpload', {
            id: this.organizationForm.orgId
          });
        }
      }
    });
    if (this.$router.currentRoute.name === 'Organization') {
      this.isCurrentPath = true;
    }
  },
  methods: {
    addEditOrganization() {
      const getOrgSpecification = this.orgGeneralSpecList.map(spec => {
        return {
          end_date: commonHelper.formattedDate(spec.start_date_end_date[1]),
          org_spec_cat_vset: spec.selectedOrgSpecCat.value,
          org_spec_detail: spec.org_spec_detail,
          org_spec_id: spec.org_spec_id,
          org_spec_vset: spec.orgSelectedSpec.value,
          start_date: commonHelper.formattedDate(spec.start_date_end_date[0])
        };
      });
      const orgSpecDetails = getOrgSpecification.filter(key =>
        key.org_spec_detail !== '' && key.org_spec_detail !== null
          ? getOrgSpecification
          : null
      );
      const payload = {
        address_id: this.organizationForm.address_id,
        employee_num_auto_lpad: this.organizationForm.emp_num_auto_lpad,
        employee_num_prefix: this.organizationForm.employee_num_prefix,
        erp_org_id: this.organizationForm.erp_org_id,
        hrms_flex_field_id: this.organizationForm.hrms_flex_field_id,
        org_description: this.organizationForm.org_description,
        org_id: this.organizationForm.org_id,
        org_legal_status_lookup: this.organizationForm.slectedOrgLegalType
          .value,
        org_name: this.organizationForm.org_name,
        org_parent_id: this.organizationForm.slectedOrgParentName.value,
        org_short_name: this.organizationForm.org_short_name,
        org_type_lookup: this.organizationForm.org_type.value,
        organization_specifications: orgSpecDetails
      };
      this.loader = true;
      this.$store
        .dispatch('organization/addEditOrganization', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.showReceiptPreview = false;
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
          this.getOrganizationList();
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getOrganizationType() {
      this.$store
        .dispatch('shared/getDataFromLookupsByLookupType', 'SECGRP')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const orgType = results.map(type => {
              return {
                value: type.lookup_code.toUpperCase(),
                text: type.meaning
              };
            });
            this.organizationList = orgType;
            this.orgTypeData = orgType;
            if (this.orgTypeData !== null && this.orgTypeData.length > 0) {
              this.defaultOrgTypeCode = store.state.shared.orgType.orgType
                ? store.state.shared.orgType.orgType
                : this.orgTypeData[0].value;
            }
          }
        });
    },
    getOrgAddressType() {
      this.$store.dispatch('organization/getAddressType').then(response => {
        const results = response.data.data;
        const addressType = results.map(type => {
          return {
            value: type.lookup_code.toUpperCase(),
            text: type.meaning
          };
        });
        this.addressTypeList = addressType;
      });
    },
    getOrgLegalType() {
      this.$store
        .dispatch('shared/getDataFromLookupsByLookupType', 'ORG_LEGAL_STATUS')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const orgType = results.map(type => {
              return {
                value: type.lookup_code.toUpperCase(),
                text: type.meaning
              };
            });
            this.orgLegalTypeList = orgType;
          }
        });
    },
    onSelectOrgType(event) {
      this.organizationForm.org_type = event;
      this.getOrgParentTypeName(event.value);
    },
    getOrgParentTypeName(lookupcode) {
      this.$store
        .dispatch('organization/getParentOrgType', lookupcode)
        .then(response => {
          this.getParentOrgName(response.data.data);
        });
    },
    getParentOrgName(orgType) {
      const queryParams = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        org_type: orgType
      };
      this.loader = true;
      this.$store
        .dispatch('organization/getOrganizationList', queryParams)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page;
            const valueAndType = result.map(type => {
              return {
                value: type.org_id,
                text: type.org_name
              };
            });
            this.orgParentNameList = valueAndType;
            this.organizationForm.slectedOrgParentName =
              valueAndType.length === 1
                ? valueAndType[0]
                : this.organizationForm.slectedOrgParentName;
            if (this.orgParentNameList.length === 0) {
              this.orgParentNameList.push({
                value: null,
                text: 'No record found'
              });
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSpecCategory() {
      const payload = {
        lovType: 'SPEC_CAT',
        parent_value_set_id: null
      };
      this.$store
        .dispatch('shared/getLOVBySetTypeWithValueSet', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const orgType = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.orgSpecCatData = orgType;
            this.apiOrgSpecCatList = results;
          }
        });
    },
    selectedSpecCatFun(event, index) {
      // this.orgGeneralSpecList[index].selectedOrgSpecCat = event;
      this.getSpecLoopup(event, index);
    },
    getSpecLoopup(specCat, index) {
      const findObj = this.apiOrgSpecCatList.find(
        cat => cat.value_code === specCat.value
      );
      const payload = {
        lovType: 'ORG_SPEC',
        parent_value_set_id: findObj.value_set_dtl_id
      };
      this.$store
        .dispatch('shared/getLOVBySetTypeWithValueSet', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const orgType = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.orgGeneralSpecList[index].orgSpecLookupList = orgType;
            this.categoryBasedSpecData = orgType;
          }
        });
    },
    getOrganizationList() {
      this.loader = true;
      let queryParams = {};
      const orgType =
        store.state.shared.orgType && store.state.shared.orgType.orgType
          ? store.state.shared.orgType.orgType
          : store.state.shared.orgType;
      if (this.$route.name == 'Organization') {
        queryParams = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          org_type: this.defaultOrgTypeCode,
          org_short_name: this.shortNameSearch,
          org_name: this.orgNameSearch,
          parent_org_name: this.parentOrgSearch
        };
      } else {
        queryParams = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          org_type: store.state.shared.orgType.orgType
            ? store.state.shared.orgType.orgType
            : this.defaultOrgTypeCode,
          org_short_name: this.shortNameSearch,
          org_name: this.orgNameSearch,
          parent_org_name: this.parentOrgSearch,
          user_id: orgType !== 'LOC' ? store.state.auth.userId : null,
          responsibility_id:
            orgType !== 'LOC' ? store.state.shared.responsibilityId : null
        };
        if (store.state.shared.orgType.orgType === 'OU') {
          queryParams.org_parent_id = store.state.shared.orgType.parentLeId;
        }
      }
      this.payload = queryParams;
      this.$store
        .dispatch('organization/getOrganizationList', queryParams)
        .then(response => {
          this.loader = false;
          this.data = response.data.data.page;
          this.totalRows = response.data.data.total_elements;
          this.organizationListData = this.data;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected: function(item) {
      this.showAlert = false;
      const orgType =
        store.state.shared.orgType && store.state.shared.orgType.orgType
          ? store.state.shared.orgType.orgType
          : store.state.shared.orgType;
      if (store.state.party.partyId) {
        this.eventBus.$emit('getOu', {
          ou_id: item.org_id,
          ou_name: item.org_name
        });
      } else if (orgType === 'LE' || orgType === 'OU' || orgType === 'LOC') {
        this.eventBus.$emit('getOu', {
          id: item.org_id,
          name: item.org_name
        });
        this.$emit('selectedLe', item);
        this.$emit('selectedOu', item);
      } else {
        this.showModal = true;
        this.orgId = item.org_id;
        // this.organizationForm = item;
        this.getOrgDetails(this.orgId);
      }
    },
    getOrgDetails(orgId) {
      this.loader = true;
      this.$store
        .dispatch('organization/getOrgDetailsById', orgId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            this.organizationForm = {
              org_type: {
                value: result.org_type_lookup,
                text: result.org_type
              },
              selectedAddressType: {
                value: result.address_type_lookup,
                text: result.address_type
              },
              slectedOrgLegalType: {
                value: result.org_legal_status_lookup,
                text: result.org_legal_status
              },
              slectedOrgParentName: {
                value: result.org_parent_id,
                text: result.parent_name
              },
              org_name: result.org_name,
              org_description: result.org_description,
              org_short_name: result.org_short_name,
              parent_name: result.parent_name,
              address_name: result.address_name,
              address: result.address,
              address_id: result.address_id,
              erp_org_id: result.erp_org_id,
              org_id: result.org_id,
              hrms_flex_field_id: result.hrms_flexfield_id,
              hrms_flex_field_name: null,
              org_parent_id: result.org_parent_id,
              emp_num_auto_lpad: result.employee_num_auto_lpad,
              employee_num_prefix: result.employee_num_prefix
            };
            this.getOrgParentTypeName(this.organizationForm.org_type.value);
            this.$emit('leDetails', this.organizationForm);
            if (
              result.organization_specifications &&
              result.organization_specifications.length > 0
            ) {
              const orgSpecList = result.organization_specifications.map(
                spec => {
                  return {
                    org_spec_id: spec.org_spec_id,
                    org_spec_cat: spec.org_spec_cat,
                    org_spec: spec.org_spec,
                    org_spec_detail: spec.org_spec_detail,
                    start_date: spec.start_date,
                    end_date: spec.end_date,
                    selectedOrgSpecCat: {
                      value: spec.org_spec_cat_vset,
                      text: spec.org_spec_cat
                    },
                    orgSpecLookupList: [],
                    orgSelectedSpec: {
                      value: spec.org_spec_vset,
                      text: spec.org_spec
                    }
                  };
                }
              );
              this.orgGeneralSpecList = orgSpecList.map(data => {
                data.start_date_end_date = [
                  new Date(data.start_date),
                  new Date(data.end_date)
                ];
                return data;
              });
              this.orgGeneralSpecList.forEach((element, index) => {
                this.selectedSpecCatFun(element.selectedOrgSpecCat, index);
              });
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.pageOptions[5].value = filteredItems.length;
    },
    resetModal() {
      this.$emit('clicked', 'view');
      this.editMode = false;
      this.resetAddOrgFields();
    },
    addGeneralNewRow() {
      if (this.editMode) {
        this.orgGeneralSpecList.push({
          org_spec_id: 0,
          org_spec_cat: null,
          org_spec: null,
          org_spec_detail: null,
          start_date: format(new Date(), 'dd-MMM-yyyy'),
          end_date: null,
          start_date_end_date: [],
          selectedOrgSpecCat: {
            value: null,
            text: null
          },
          orgSpecLookupList: [],
          orgSelectedSpec: {
            value: null,
            text: null
          }
        });
      }
    },
    removeGeneralRow(index) {
      if (this.editMode) {
        this.orgGeneralSpecList.splice(index, 1);
      }
    },
    resetAddressModal() {
      this.showAddAddress = false;
    },
    getAddressObj(address) {
      if (address) {
        this.showAddAddress = false;
        this.organizationForm.address = address.address_line;
        this.organizationForm.address_id = address.address_id;
        this.organizationForm.selectedAddressType.value =
          address.address_type.value;
        this.organizationForm.selectedAddressType.text =
          address.address_type.text;
        this.organizationForm.address_name = address.address_name;
      }
    },
    openAddressModal() {
      this.showAddAddress = true;
      setTimeout(() => {
        this.eventBus.$emit(
          'orgAddressType',
          this.organizationForm.selectedAddressType
        );
      }, 0);
    },

    orgSpecCategoryModal(flag, index) {
      this.catIndex = index;
      this.orgSpecCatModal = flag;
    },
    selectedOrgCat(item) {
      this.orgGeneralSpecList[this.catIndex].selectedOrgSpecCat.text =
        item.text;
      this.orgGeneralSpecList[this.catIndex].selectedOrgSpecCat.value =
        item.value;
      this.orgSpecCatModal = false;
      this.selectedSpecCatFun(item, this.catIndex);
    },
    orgSpecificationModal(flag, index) {
      this.specIndex = index;
      this.orgSpecModal = flag;
    },
    selectedOrgSpec(item) {
      this.orgGeneralSpecList[this.specIndex].orgSelectedSpec.text = item.text;
      this.orgGeneralSpecList[this.specIndex].orgSelectedSpec.value =
        item.value;
      this.orgSpecModal = false;
    },
    clearOrgSearchFields() {
      this.defaultOrgTypeCode = '';
      this.orgNameSearch = null;
      this.shortNameSearch = null;
      this.parentOrgSearch = null;

      this.getOrganizationType();
      this.getOrganizationList();
      this.getSpecCategory();
      this.getOrgAddressType();
      this.getOrgLegalType();
    },
    resetAddOrgFields() {
      this.showAlert = false;
      this.organizationForm = {
        org_type: {
          value: '',
          text: ''
        },
        selectedAddressType: {
          value: null,
          text: null
        },
        slectedOrgLegalType: {
          value: null,
          text: null
        },
        slectedOrgParentName: {
          value: null,
          text: null
        },
        org_name: '',
        org_description: '',
        org_short_name: '',
        parent_name: '',
        address_name: '',
        address: '',
        address_id: 0,
        erp_org_id: 0,
        org_id: 0,
        hrms_flex_field_id: 0,
        hrms_flex_field_name: null,
        org_parent_id: 0,
        emp_num_auto_lpad: null,
        employee_num_prefix: null
      };
      this.orgGeneralSpecList = [
        {
          org_spec_id: 0,
          org_spec_cat: null,
          org_spec: null,
          org_spec_detail: null,
          start_date: format(new Date(), 'dd-MMM-yyyy'),
          end_date: null,
          start_date_end_date: [],
          selectedOrgSpecCat: {
            value: null,
            text: null
          },
          orgSpecLookupList: [],
          orgSelectedSpec: {
            value: null,
            text: null
          }
        }
      ];
    },
    getFlexField(flexfield) {
      this.organizationForm.hrms_flex_field_id = flexfield.flexfield_id;
      this.organizationForm.hrms_flex_field_name = flexfield.flexfield_name;
      this.showFlexFieldModal = false;
    },
    resetFlexFieldModal() {
      this.showFlexFieldModal = false;
    }
  },
  beforeDestroy() {
    this.eventBus.$off('orgAddressType');
    this.unsubscribe();
  }
};
