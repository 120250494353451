import { BasicSelect, ModelSelect } from 'vue-search-select';
import Organization from '../../../../admin/organization/';
import Currency from '../../../../admin/currency/';
import SiteAddress from './siteAddress/';
import store from '../../../../../../store/';
import appStrings from '@/app/utility/string.utility.js';
import {
  required,
  integer,
  maxValue,
  minValue
} from 'vuelidate/lib/validators';

export default {
  name: 'site',
  components: {
    BasicSelect,
    Organization,
    Currency,
    SiteAddress,
    ModelSelect
  },
  data() {
    return {
      unsubscribe: null,
      partyProfileType: '',
      isAddressTypeBill: true,
      openAddressModal: false,
      openCurrencyModal: false,
      showValueSetModal: false,
      showCreateCustomerSiteModal: false,
      editMode: false,
      loader: false,
      showBankModal: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      partyId: null,
      customerId: '',
      vendorId: '',
      customerSiteList: [],
      openOuModal: false,
      siteTypeList: [
        {
          value: null,
          text: null
        }
      ],
      siteForm: {
        customer_site_id: 0,
        selectedSiteType: {
          value: null,
          text: null
        },
        site_name: null,
        site_type: null,
        bill_to_address: null,
        bill_to_address_id: '',
        ship_to_address: null,
        ship_to_address_id: '',
        ou_name: null,
        ou_id: null,
        currency: null,
        currency_id: null,
        active: true,
        sez_flag: false,
        multi_currency: false,
        vendor_site_id: 0,
        purchasing_site: false,
        pay_site: false,
        req_site: false,
        payment_term_name: null,
        payment_term_id: null,
        payment_priority: 99,
        pay_group_meaning: null,
        pay_group: null,
        payment_mode_vset: null,
        payment_mode_vset_meaning: null,
        tax_schedule_hold: false,
        item_schedule_hold: false,
        default_tax_section_id: 0,
        default_tax_setion_id_name: null,
        erp_site_id: 0,
        erp_vendor_site_name: null
      },
      payment_term: {
        id: null,
        name: null
      },
      pay_group_vset: {
        value: null,
        text: null
      },
      payment_mode: {
        value: null,
        text: null
      },
      tax_section: {
        id: null,
        name: null
      },
      active: true,
      cusomerSiteFields: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address'
        },
        {
          key: 'ship_to_address'
        },
        {
          key: 'ou_name',
          label: 'OU Name'
        },
        {
          key: 'le_name',
          label: 'LE Name'
        },
        {
          key: 'currency'
        },
        {
          key: 'active'
        },
        {
          key: 'multi_currency'
        },
        {
          key: 'sez_flag',
          label: 'SEZ Flag'
        },
        {
          key: 'ship_to_address_id',
          class: 'd-none'
        },
        {
          key: 'bill_to_address_id',
          class: 'd-none'
        },
        {
          key: 'customer_site_id',
          class: 'd-none'
        },
        {
          key: 'currency_id',
          class: 'd-none'
        },
        {
          key: 'ou_id',
          class: 'd-none'
        },
        {
          key: 'payment_term_name'
        },
        {
          key: 'created_by'
        },

        {
          key: 'creation_date'
        },
        {
          key: 'last_update_date'
        },
        {
          key: 'last_updated_by'
        }
      ],
      vendorSiteDataList: [],
      vendorSiteFields: [
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address'
        },
        {
          key: 'bill_to_address_id',
          class: 'd-none'
        },
        {
          key: 'ship_to_address'
        },
        {
          key: 'ship_to_address_id',
          class: 'd-none'
        },
        {
          key: 'ou_name',
          label: 'OU Name'
        },
        {
          key: 'le_name',
          label: 'LE Name'
        },
        {
          key: 'ou_id',
          class: 'd-none'
        },
        {
          key: 'vendor_site_id',
          class: 'd-none'
        },
        {
          key: 'currency'
        },
        {
          key: 'payment_term_name',
          label: 'Payment Term'
        },
        {
          key: 'payment_priority'
        },
        {
          key: 'pay_group_meaning',
          label: 'Pay Group'
        },
        {
          key: 'payment_mode_vset_meaning',
          label: 'Payment Mode'
        },
        {
          key: 'default_tax_setion_id_name',
          label: 'Default Tax Section'
        },
        {
          key: 'multi_currency'
        },
        {
          key: 'purchasing_site'
        },
        {
          key: 'pay_site'
        },
        {
          key: 'req_site'
        },
        {
          key: 'tax_schedule_hold'
        },
        {
          key: 'item_schedule_hold'
        },
        {
          key: 'erp_site_id'
        },
        {
          key: 'erp_vendor_site_name'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'last_update_date'
        },
        {
          key: 'last_updated_by'
        }
      ],
      leName: {
        name: null,
        id: null
      },
      ouName: {
        name: null,
        id: null
      },
      parent_value_set_id: null
    };
  },
  validations: {
    siteForm: {
      site_name: { required },
      selectedSiteType: {
        value: { required },
        text: { required }
      },
      bill_to_address: { required },
      ship_to_address: { required },
      ou_name: { required },
      currency: { required },
      payment_priority: {
        integer,
        minValue: minValue(1),
        maxValue: maxValue(99)
      }
    }
  },
  mounted() {
    this.eventBus.$off('customerSite');
    this.eventBus.$off('setSiteAddress');
    this.eventBus.$off('getOu');
    this.eventBus.$off('getCurrency');
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (store.state.party.customerTabValue === 'customerSite') {
          if (actionName === 'add') {
            this.resetForm();
            this.showCreateCustomerSiteModal = true;
            this.editMode = true;
          }
          if (actionName === 'update') {
            this.editMode = true;
          }
          if (
            actionName === 'save' &&
            this.showCreateCustomerSiteModal &&
            this.editMode
          ) {
            this.submitSiteForm();
          }
        }
      }
    });
    this.eventBus.$on('customerSite', customerSite => {
      this.partyProfileType = store.state.party.partyProfile;
      if (store.state.party.partyProfile === 'vendor') {
        this.vendorSiteDataList = customerSite.vendor_sites;
        this.vendorId = customerSite.vendor_id;
      } else if (store.state.party.partyProfile === 'customer') {
        this.customerId = customerSite.customer_id;
        this.getCustomerSiteByPartyId();
      }
      this.getSiteType();
    });
    this.eventBus.$on('setSiteAddress', setSiteAddress => {
      if (this.isAddressTypeBill) {
        this.siteForm.bill_to_address = setSiteAddress.address;
        this.siteForm.bill_to_address_id = setSiteAddress.address_id;
      } else {
        this.siteForm.ship_to_address = setSiteAddress.address;
        this.siteForm.ship_to_address_id = setSiteAddress.address_id;
      }
      this.openAddressModal = false;
    });
    this.eventBus.$on('getOu', getOu => {
      this.siteForm.ou_name = getOu.ou_name;
      this.siteForm.ou_id = getOu.ou_id;
      this.openOuModal = false;
    });
    this.eventBus.$on('getCurrency', getCurrency => {
      this.siteForm.currency = getCurrency.currency;
      this.siteForm.currency_id = getCurrency.currency_id;
      this.openCurrencyModal = false;
    });
  },
  methods: {
    submitSiteForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (store.state.party.partyProfile === 'customer') {
          this.addEditCustomerSite();
        } else if (store.state.party.partyProfile === 'vendor') {
          this.addEditVendorSite();
        }
      }
    },
    getCustomerSiteByPartyId() {
      const payload = {
        partyId: store.state.party.partyId,
        flag: {
          active: this.active,
          ou_id: this.ouName.id,
          parent_id: this.leName.id
        }
      };
      this.loader = true;
      this.$store
        .dispatch('party/getCustomerSiteByPartyId', payload)
        .then(response => {
          this.customerSiteList = response.data.data.customer_site_lists;
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSiteType() {
      this.$store
        .dispatch('shared/getLOVBySetType', 'SITE_TYPE')
        .then(response => {
          if (response.status === 200) {
            const result = response.data.data;
            const siteTypeArr = result.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.siteTypeList = siteTypeArr;
          }
        });
    },
    siteRowSelected(item) {
      if (this.$route.name === 'searchParty') {
        this.siteForm = {
          site_name: item.site_name,
          selectedSiteType: {
            value: item.site_type_vset,
            text: item.site_type
          },
          bill_to_address: item.bill_to_address,
          ship_to_address: item.ship_to_address,
          ou_name: item.ou_name,
          currency: item.currency,
          active: item.active,
          ship_to_address_id: item.ship_to_address_id,
          bill_to_address_id: item.bill_to_address_id,
          ou_id: item.ou_id,
          currency_id: item.currency_id,
          customer_site_id: item.customer_site_id,
          multi_currency: item.multi_currency,
          sez_flag: item.sez_flag
        };
        this.payment_term = {
          id: item.payment_term_id,
          name: item.payment_term_name
        };
        this.editMode = false;
        this.showCreateCustomerSiteModal = true;
      } else {
        this.eventBus.$emit('setCustomerData', {
          customerData: item,
          customerId: this.customerId
        });
      }
    },
    vendorSiteRowSelected(item) {
      if (this.$route.name === 'searchParty') {
        this.siteForm = {
          site_name: item.site_name,
          selectedSiteType: {
            value: item.site_type_vset,
            text: item.site_type
          },
          bill_to_address: item.bill_to_address,
          ship_to_address: item.ship_to_address,
          ou_name: item.ou_name,
          currency: item.currency,
          active: item.active,
          ship_to_address_id: item.ship_to_address_id,
          bill_to_address_id: item.bill_to_address_id,
          ou_id: item.ou_id,
          currency_id: item.currency_id,
          vendor_site_id: item.vendor_site_id,
          multi_currency: item.multi_currency,
          purchasing_site: item.purchasing_site,
          pay_site: item.pay_site,
          req_site: item.req_site,
          payment_priority: item.payment_priority,
          // pay_group: item.pay_group,
          // pay_group_meaning: item.pay_group_meaning,
          // payment_mode_vset: item.payment_mode_vset,
          // payment_mode_vset_meaning: item.payment_mode_vset_meaning,
          // default_tax_section_id: item.default_tax_section_id,
          // default_tax_setion_id_name: item.default_tax_setion_id_name,
          erp_site_id: item.erp_site_id,
          erp_vendor_site_name: item.erp_vendor_site_name,
          item_schedule_hold: item.item_schedule_hold,
          tax_schedule_hold: item.tax_schedule_hold
        };
        this.payment_term = {
          id: item.payment_term_id,
          name: item.payment_term_name
        };
        this.pay_group_vset = {
          value: item.pay_group,
          text: item.pay_group_meaning
        };
        this.payment_mode = {
          value: item.payment_mode_vset,
          text: item.payment_mode_vset_meaning
        };
        this.tax_section = {
          id: item.default_tax_section_id,
          name: item.default_tax_setion_id_name
        };
        this.editMode = false;
        this.showCreateCustomerSiteModal = true;
      } else {
        this.eventBus.$emit('setVendorData', {
          vendorData: item,
          vendorId: this.vendorId
        });
        // this.$store.dispatch('shared/setVendorData', {
        //   vendorData: item,
        //   vendorId: this.vendorId
        // });
      }
    },
    closeTabs() {
      this.eventBus.$emit('closeCustomer', false);
    },
    resetModal() {
      //   this.eventBus.$off();
    },
    selectedSite() {},
    openCloseOu(flag) {
      this.openOuModal = flag;
      const dataToGetOrg = {
        orgType: 'OU',
        parentLeId: null
      };
      this.$store.dispatch('shared/setUnsetOrgType', dataToGetOrg);
    },
    openCloseCurrency(flag) {
      this.openCurrencyModal = flag;
    },
    openCloseAddress(flag, addressType) {
      this.isAddressTypeBill = addressType === 'bill' ? true : false;
      this.openAddressModal = flag;
      setTimeout(() => {
        this.eventBus.$emit('siteAddress', '');
      }, 0);
    },
    addEditCustomerSite() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          customerId: this.customerId,
          customerSiteData: {
            customer_sites: [
              {
                active: this.siteForm.active,
                multi_currency: this.siteForm.multi_currency,
                sez_flag: this.siteForm.sez_flag,
                site_name: this.siteForm.site_name,
                site_type_vset: this.siteForm.selectedSiteType.value,
                bill_to_address_id: this.siteForm.bill_to_address_id,
                currency_id: this.siteForm.currency_id,
                customer_site_id: this.siteForm.customer_site_id,
                ou_id: this.siteForm.ou_id,
                ship_to_address_id: this.siteForm.ship_to_address_id,
                payment_term_id: this.payment_term.id
              }
            ]
          }
        };
        this.loader = true;
        this.$store
          .dispatch('party/addEditCustomerSite', payload)
          .then(response => {
            this.showCreateCustomerSiteModal = false;
            this.customerSiteList = response.data.data.customer_sites;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.loader = false;
          })
          .catch(error => {
            this.loader = false;
            alert(error.message);
          });
      }
    },
    addEditVendorSite() {
      this.$v.$touch();
      const flag = true;
      if (flag) {
        const payload = {
          vendorId: this.vendorId,
          vendorSiteData: {
            vendor_sites: [
              {
                site_name: this.siteForm.site_name,
                site_type_vset: this.siteForm.selectedSiteType.value,
                bill_to_address_id: this.siteForm.bill_to_address_id,
                ship_to_address_id: this.siteForm.ship_to_address_id,
                ou_id: this.siteForm.ou_id,
                currency_id: this.siteForm.currency_id,
                payment_term_id: this.payment_term.id,
                payment_priority: this.siteForm.payment_priority,
                pay_group: this.pay_group_vset.value,
                payment_mode_vset: this.payment_mode.value,
                default_tax_section_id: this.tax_section.id,
                active: this.siteForm.active,
                multi_currency: this.siteForm.multi_currency,
                purchasing_site: this.siteForm.purchasing_site,
                pay_site: this.siteForm.pay_site,
                req_site: this.siteForm.req_site,
                tax_schedule_hold: this.siteForm.tax_schedule_hold,
                item_schedule_hold: this.siteForm.item_schedule_hold,
                vendor_site_id: this.siteForm.vendor_site_id
              }
            ]
          }
        };
        this.loader = true;
        this.$store
          .dispatch('party/addEditPartyVendorSite', payload)
          .then(response => {
            this.showCreateCustomerSiteModal = false;
            this.vendorSiteDataList = response.data.data.vendor_sites;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.loader = false;
          })
          .catch(error => {
            this.loader = false;
            alert(error.message);
          });
      }
    },
    resetForm() {
      this.siteForm = {
        customer_site_id: 0,
        selectedSiteType: {
          value: null,
          text: null
        },
        site_name: null,
        site_type: null,
        bill_to_address: null,
        bill_to_address_id: '',
        ship_to_address: null,
        ship_to_address_id: '',
        ou_name: null,
        ou_id: null,
        currency: null,
        currency_id: null,
        active: true,
        sez_flag: false,
        multi_currency: false,
        vendor_site_id: 0,
        purchasing_site: false,
        pay_site: false,
        req_site: false,
        tax_schedule_hold: false,
        item_schedule_hold: false,
        default_tax_section_id: 0,
        default_tax_setion_id_name: null,
        erp_site_id: null,
        erp_vendor_site_name: null
      };
      this.payment_term = {
        id: null,
        name: null
      };
      this.pay_group_vset = {
        value: null,
        text: null
      };
      this.payment_mode = {
        value: null,
        text: null
      };
      this.tax_section = {
        id: null,
        name: null
      };
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'FMS_PAYMENT_TERM') {
        this.payment_term = {
          id: item.value_set_dtl_id,
          name: item.value_code
        };
      } else if (this.vsetCode === 'PAY_GROUP_TYPE') {
        this.pay_group_vset = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === 'PAYMENT_MODE') {
        this.payment_mode = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === 'TAX_SECTION_NAME_VSET') {
        this.tax_section = {
          id: item.value_set_dtl_id,
          name: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          id: item.value_set_dtl_id,
          name: item.value_code
        };
        this.ouName = {
          id: null,
          name: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.ouName = {
          id: item.value_set_dtl_id,
          name: item.value_code
        };
      }
    },
    openValueSetModal(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.leName.id;
      } else {
        this.parent_value_set_id = null;
      }
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    getVendorSiteByPartyId() {
      const payload = {
        partyId: store.state.party.partyId,
        flag: {
          active: this.active,
          ou_id: this.ouName.id,
          parent_id: this.leName.id,
        }
      };
      this.loader = true;
      this.$store
        .dispatch('party/getPartyVenderSiteById', payload)
        .then(response => {
          this.vendorSiteDataList = response.data.data.vendor_sites;
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    checkActiveVendor() {
      if (store.state.party.partyProfile === 'customer') {
        this.getCustomerSiteByPartyId();
      } else if (store.state.party.partyProfile === 'vendor') {
        this.getVendorSiteByPartyId();
      }
    },
    clearVendorSiteFilter() {
      this.active = true;
      this.leName = {
        name: null,
        id: null
      };
      this.ouName = {
        name: null,
        id: null
      };
      if (store.state.party.partyProfile === 'customer') {
        this.getCustomerSiteByPartyId();
      } else if (store.state.party.partyProfile === 'vendor') {
        this.getVendorSiteByPartyId();
      }
    },
    searchSites() {
      if (store.state.party.partyProfile === 'customer') {
        this.getCustomerSiteByPartyId();
      } else if (store.state.party.partyProfile === 'vendor') {
        this.getVendorSiteByPartyId();
      }
    },
    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case 'TAX_SECTION_NAME_VSET':
          this.tax_section = {
            id: null,
            name: null
          };
          break;
      }
    }
  },
  beforeDestroy() {
    this.eventBus.$off('getOu');
    this.$store.dispatch('shared/setUnsetOrgType', null);
    this.unsubscribe();
  }
};
