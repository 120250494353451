import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const getLOVBySetType = (context, payload) => {
  const url = URL_UTILITY.getLOVBySetTypeUrl.replace(
    '{valueSetType}',
    payload.lovType
  );

  const response = http.getApi(
    `${
      !payload.parent_value_set_id
        ? url
        : url + '?parent_value_set_id=' + payload.parent_value_set_id
    }`
  );
  return response;
};

const getPartyList = (context, payload) => {
  const url = URL_UTILITY.getPartyListUrl;
  const response = http.getApi(url, payload);
  return response;
};

const getPartyDetailsById = (context, partyId) => {
  const url = URL_UTILITY.getPartyDetailsByIdUrl.replace('{partyId}', partyId);
  const response = http.getApi(url);
  return response;
};

const createParty = (context, createPartyPayload) => {
  const url = URL_UTILITY.getCreatePartyUrl;
  const response = http.postApi(url, createPartyPayload);
  return response;
};

const editParty = (contect, partyPayloadObj) => {
  const url = URL_UTILITY.getPartyDetailsByIdUrl.replace(
    '{partyId}',
    partyPayloadObj.party_id
  );
  const response = http.putApi(url, partyPayloadObj.ediPartyFormData);
  return response;
};

const addUpdatePartyAddress = (context, payload) => {
  const url = URL_UTILITY.getSavePartyAddressUrl.replace(
    '{partyId}',
    payload.partyId
  );
  const response = http.postApi(url, payload.addressPayload);
  return response;
};

const addPartyBank = (context, payload) => {
  const url = URL_UTILITY.getSavePartyBankUrl.replace(
    '{partyId}',
    payload.partyId
  );
  const response = http.postApi(url, payload.bankPayload);
  return response;
};

const addPartyContactDetails = (context, payload) => {
  const url = URL_UTILITY.getSavePartyContactDeatilUr.replace(
    '{partyId}',
    payload.partyId
  );
  const response = http.postApi(url, payload.contactPayload);
  return response;
};

const getTaxGroup = (contex, taxType) => {
  const url = URL_UTILITY.getTaxGroupUrl.replace('{taxType}', taxType);
  const response = http.getApi(url);
  return response;
};

const getDirectTax = (context, payload) => {
  const url = URL_UTILITY.getDirectTaxUrl.replace('{partyId}', payload.partyId);
  const response = http.postApi(url, payload.directTaxPayload);
  return response;
};
const getIndirectTax = (context, payload) => {
  const url = URL_UTILITY.getIndirectTaxUrl.replace(
    '{partyId}',
    payload.partyId
  );
  const response = http.postApi(url, payload.indirectTaxPayload);
  return response;
};

const getEmployee = (context, searchString) => {
  const url = URL_UTILITY.getPartyEmployeeUrl.replace(
    '{employeeDtl}',
    searchString
  );
  const response = http.getApi(url);
  return response;
};
const createPartyCustomer = (context, payload) => {
  if (payload.isCustomerCreated) {
    const url = URL_UTILITY.getPartyCustomerCreateUrl.replace(
      '{partyId}',
      payload.partyId
    );
    const response = http.getApi(url);
    return response;
  } else {
    const url = URL_UTILITY.getPartyCustomerCreateUrl.replace(
      '{partyId}',
      payload.partyId
    );
    const response = http.postApi(url);
    return response;
  }
};

const createPartyVendor = (context, partyId) => {
  const url = URL_UTILITY.getPartyVendorCreateUrl.replace('{partyId}', partyId);
  const response = http.postApi(url);
  return response;
};

const getPartyAddressByPartyId = (context, partyId) => {
  const url = URL_UTILITY.getPartyAddressByPartyIdUrl.replace(
    '{partyId}',
    partyId
  );
  const response = http.getApi(url);
  return response;
};

const addEditCustomerSite = (context, payload) => {
  const url = URL_UTILITY.getAddEditCustomerSiteUrl.replace(
    '{customerId}',
    payload.customerId
  );
  const response = http.postApi(url, payload.customerSiteData);
  return response;
};

const setUnsetPartyId = (context, partyId = null) => {
  context.commit('setPartyId', partyId);
};

const setUnsetPartyCustomerTabValue = (context, tabValue = null) => {
  context.commit('partyCustomerTabValue', tabValue);
};

const getPartyBanksListUrl = (context, partyId) => {
  const url = URL_UTILITY.getPartyBanksListUrl.replace('{partyId}', partyId);
  const result = http.getApi(url);
  return result;
};

const getAddEdiCustomerSiteBank = (context, payload) => {
  const url = URL_UTILITY.getAddEditPartyCustomerSiteBankUrl.replace(
    '{customerId}',
    payload.customerId
  );
  const result = http.postApi(url, payload.customerSiteBank);
  return result;
};

const getPartyContactsByPartyId = (context, partyId) => {
  const url = URL_UTILITY.getContactsByPartyIdUrl.replace('{partyId}', partyId);
  const result = http.getApi(url);
  return result;
};

const getAddEditCustomerSiteContact = (context, payload) => {
  const url = URL_UTILITY.getAddEditPartyCustomerContactUrl.replace(
    '{customerId}',
    payload.customerId
  );
  const result = http.postApi(url, payload.contactpayload);
  return result;
};

const getPartyDirectTaxList = (context, partyId) => {
  const url = URL_UTILITY.getPartyDirectTaxListByPartyIdUrl.replace(
    '{partyId}',
    partyId
  );
  const result = http.getApi(url);
  return result;
};

const getPartyIndirectTaxList = (context, partyId) => {
  const url = URL_UTILITY.getPartyIndirectTaxListByPartyIdUrl.replace(
    '{partyId}',
    partyId
  );
  const result = http.getApi(url);
  return result;
};
const getAddEditCustomerDirectTax = (context, payload) => {
  const url = URL_UTILITY.getAddEditPartyCustomerDirectTaxUrl.replace(
    '{customerId}',
    payload.customerId
  );
  const result = http.postApi(url, payload.customerSiteDirectTax);
  return result;
};

const getAddEditCustomerIndirectTax = (context, payload) => {
  const url = URL_UTILITY.getAddEditPartyCustomerIndirectTaxUrl.replace(
    '{customerId}',
    payload.customerId
  );
  const result = http.postApi(url, payload.customerSiteIndirectTax);
  return result;
};

const getCustomerCcidDetils = (context, payload) => {
  const url = URL_UTILITY.getFlexfieldAccountingUrl;
  return http.getApi(url, payload.search);
};

const addEditCustomerSiteAccount = (context, payload) => {
  const url = URL_UTILITY.getAddEditCustomerSiteAccountUrl.replace(
    '{customerId}',
    payload.customerId
  );
  return http.postApi(url, payload.siteAccountData);
};
const getPartyVenderSiteList = (context, partyId) => {
  const url = URL_UTILITY.getPartyVendorSiteListUrl.replace(
    '{partyId}',
    partyId
  );
  return http.getApi(url);
};
const setPartyProfile = (context, profileType) => {
  context.commit('partyProfileType', profileType);
};
const addEditPartyVendorSite = (context, payload) => {
  const url = URL_UTILITY.getVendorAddEditSiteUrl.replace(
    '{vendorId}',
    payload.vendorId
  );
  return http.postApi(url, payload.vendorSiteData);
};
const getVendorBankList = (context, vendorId) => {
  const url = URL_UTILITY.getVendorBankListUrl.replace('{vendorId}', vendorId);
  return http.getApi(url);
};
const addEditVendorBank = (context, payload) => {
  const url = URL_UTILITY.getVendorBankListUrl.replace(
    '{vendorId}',
    payload.vendorId
  );
  return http.postApi(url, payload.vendorSiteData);
};
const getVendorDirectTaxList = (context, vendorId) => {
  const url = URL_UTILITY.getVendorDirectTaxUrl.replace('{vendorId}', vendorId);
  return http.getApi(url);
};
const addEditVendorDirectTax = (context, payload) => {
  const url = URL_UTILITY.getVendorDirectTaxUrl.replace(
    '{vendorId}',
    payload.vendorId
  );
  return http.postApi(url, payload.directTadData);
};
const getVendorIndirectTaxList = (context, vendorId) => {
  const url = URL_UTILITY.getVendoreIndirectTaxUrl.replace(
    '{vendorId}',
    vendorId
  );
  return http.getApi(url);
};
const addEditVendorIndirectTax = (context, payload) => {
  const url = URL_UTILITY.getVendoreIndirectTaxUrl.replace(
    '{vendorId}',
    payload.vendorId
  );
  return http.postApi(url, payload.indirectTaxData);
};
const getVendorContacts = (context, vendorId) => {
  const url = URL_UTILITY.getVendorContactListUrl.replace(
    '{vendorId}',
    vendorId
  );
  return http.getApi(url);
};
const addEditVendorContact = (context, payload) => {
  const url = URL_UTILITY.getVendorContactListUrl.replace(
    '{vendorId}',
    payload.vendorId
  );
  return http.postApi(url, payload.contactData);
};
const getVendorCcidList = (context, payload) => {
  const url = URL_UTILITY.getVendorCcidDetailsUrl.replace(
    '{vendorSiteId}',
    payload.vendorSiteId
  );
  return http.getApi(url, payload.search);
};
const getVendorSiteAccountList = (context, vendorId) => {
  const url = URL_UTILITY.getVendorSiteAccount.replace('{vendorId}', vendorId);
  return http.getApi(url);
};
const addEditVendorSiteAccount = (context, payload) => {
  const url = URL_UTILITY.getVendorSiteAccount.replace(
    '{vendorId}',
    payload.vendorId
  );
  return http.postApi(url, payload.siteAccountData);
};
const addEditPartyBankBranch = (context, payload) => {
  const url = URL_UTILITY.getPartyBankBranchUrl.replace(
    '{partyId}',
    payload.partyId
  );
  return http.postApi(url, payload.branchData);
};
const getBankBranchList = (context, partyId) => {
  const url = URL_UTILITY.getPartyBankBranchUrl.replace('{partyId}', partyId);
  return http.getApi(url);
};
const setUnsetPartyName = (context, partyName) => {
  context.commit('setPartyname', partyName);
};

const setUnsetPartyType = (context, partyType) => {
  context.commit('setPartyType', partyType);
};

const setUnsetPartyShortName = (context, shortName) => {
  context.commit('setPartyShortName', shortName);
};

const getBankAccountList = (context, partyId) => {
  const url = URL_UTILITY.getPartyBankAccountUrl.replace('{partyId}', partyId);
  return http.getApi(url);
};
const addEditBankAccount = (context, payload) => {
  const url = URL_UTILITY.getPartyBankAccountUrl.replace(
    '{partyId}',
    payload.partyId
  );
  return http.postApi(url, payload.accountData);
};
const getReceiptMethodsList = (context, partyId) => {
  const url = URL_UTILITY.getPartyBankReceiptMethodUrl.replace(
    '{partyId}',
    partyId
  );
  return http.getApi(url);
};
const addEditBankReceiptMethod = (context, payload) => {
  const url = URL_UTILITY.getPartyBankReceiptMethodUrl.replace(
    '{partyId}',
    payload.partyId
  );
  return http.postApi(url, payload.methodData);
};
const getPaymentMethodsList = (context, partyId) => {
  const url = URL_UTILITY.getPartyBankPaymentMethodUrl.replace(
    '{partyId}',
    partyId
  );
  return http.getApi(url);
};
const addEditPaymentMethod = (context, payload) => {
  const url = URL_UTILITY.getPartyBankPaymentMethodUrl.replace(
    '{partyId}',
    payload.partyId
  );
  return http.postApi(url, payload.methodData);
};
const getBankAccountCcidDetail = (context, payload) => {
  const url = URL_UTILITY.getBankAccountCcidDetailUrl.replace(
    '{bankAccountId}',
    payload.bankAccountId
  );
  return http.getApi(url, payload.search);
};

const getCustomerIndirectTax = (context, payload) => {
  const url = URL_UTILITY.getCustomerIndirectTaxUrl.replace(
    '{partyId}',
    payload.partyId
  );
  return http.getApi(url, payload);
};

const getCustomerSiteByPartyId = (context, payload) => {
  const url = URL_UTILITY.getCustomerSiteByPartyIdUrl.replace(
    '{partyId}',
    payload.partyId
  );
  return http.getApi(url, payload.flag);
};

const getCustomerBankByPartyId = (context, payload) => {
  const url = URL_UTILITY.getCustomerBankByPartyIdUrl.replace(
    '{partyId}',
    payload.partyId
  );
  return http.getApi(url, payload);
};

const getCustomerSiteContactByPartyId = (context, payload) => {
  const url = URL_UTILITY.getCustomerSiteContactByPartyIdUrl.replace(
    '{partyId}',
    payload.partyId
  );
  return http.getApi(url, payload);
};

const getCustomerSiteAccountByPartyId = (context, payload) => {
  const url = URL_UTILITY.getCustomerSiteAccountByPartyIdUrl.replace(
    '{partyId}',
    payload.partyId
  );
  return http.getApi(url, payload);
};

const getCustomerDirectTaxByPartyId = (context, payload) => {
  const url = URL_UTILITY.getCustomerDirectTaxUrl.replace(
    '{partyId}',
    payload.partyId
  );
  return http.getApi(url, payload);
};
const getCustomerSiteById = (constext, payload) => {
  return http.getApi(
    URL_UTILITY.getCustomerSiteUrl +
      '/' +
      payload.customerId +
      '/customer-sites',
    payload.lease
  );
};

const getPartyVenderSiteById = (context, payload) => {
  const url = URL_UTILITY.getPartyVendorSiteListUrl.replace(
    '{partyId}',
    payload.partyId
  );
  return http.getApi(url, payload.lease || payload.flag);
};

const getCustomerSiteByOu = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getCustomerSiteByOuUrl +
      '/' +
      payload.customer_id +
      '/customer-sites',
    payload
  );
};
const getVendorSiteByOu = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getVendorSiteByOuUrl +
      '/' +
      payload.vendor_id +
      '/vendor-sites',
    payload
  );
};
const getCustomerHistoryByCustomerId = (context, payload) => {
  const url = URL_UTILITY.getPartyListUrl+'/'+ payload +'/customer/history'
  return http.getApi(url);
};
const getVendorHistorybyVendorId = (context, payload) => {
  const url = URL_UTILITY.getPartyListUrl+'/'+ payload +'/vendor/history'
  return http.getApi(url);
};
const getPartyHistorybyPartyId = (context, payload) => {
  const url = URL_UTILITY.getPartyListUrl+'/'+ payload +'/history'
  return http.getApi(url);
};
const uploadExcelParty = (context,excelImportExternalId) => {
  return http.postApi(URL_UTILITY.getPartyListUrl+'/'+excelImportExternalId+'/'+'excel-upload');
};
const getActive = (context, payload) => {
  return http.getApi(URL_UTILITY.getVendorSiteByOuUrl + '/' + payload.vendorId);
};
const updateActive = (context, payload) => {
  return http.postApi(URL_UTILITY.getVendorSiteByOuUrl + '/' + payload.vendorId + '?active=' +
  payload.active);
};
const postVendorOnboardExcel = (context, payload) => {
  return http.postApi(URL_UTILITY.postVendorOnboardingExcel + '/' + payload.excelImportId + '/excel-upload');
};

const getVendorUploadExcelData=(context, payload) => {
  return http.getApi(URL_UTILITY.getVendorOnboardingExcel+'/getExcelTempData', payload);
};
const getCustomerContact = (context, payload) => {
  const url = URL_UTILITY.getCustomerContactUrl + `/customers/contact/${payload.contact_id}`
  return http.getApi(url);
};
const getAddEditCustomerSiteContactNew = (context, payload) => {
  return http.postApi(URL_UTILITY.getCustomerContactUrl + `/customers/${payload.customerId}/customer-site-contacts`, payload.payload);
};

export default {
  getLOVBySetType,
  getPartyList,
  getPartyDetailsById,
  createParty,
  editParty,
  addUpdatePartyAddress,
  addPartyBank,
  addPartyContactDetails,
  getTaxGroup,
  getDirectTax,
  getIndirectTax,
  getEmployee,
  createPartyCustomer,
  createPartyVendor,
  getPartyAddressByPartyId,
  addEditCustomerSite,
  setUnsetPartyId,
  setUnsetPartyCustomerTabValue,
  getPartyBanksListUrl,
  getAddEdiCustomerSiteBank,
  getPartyContactsByPartyId,
  getAddEditCustomerSiteContact,
  getPartyDirectTaxList,
  getPartyIndirectTaxList,
  getAddEditCustomerDirectTax,
  getAddEditCustomerIndirectTax,
  getCustomerCcidDetils,
  addEditCustomerSiteAccount,
  getPartyVenderSiteList,
  setPartyProfile,
  addEditPartyVendorSite,
  getVendorBankList,
  addEditVendorBank,
  getVendorDirectTaxList,
  addEditVendorDirectTax,
  getVendorIndirectTaxList,
  addEditVendorIndirectTax,
  getVendorContacts,
  addEditVendorContact,
  getVendorCcidList,
  getVendorSiteAccountList,
  addEditVendorSiteAccount,
  addEditPartyBankBranch,
  getBankBranchList,
  setUnsetPartyName,
  getBankAccountList,
  addEditBankAccount,
  getReceiptMethodsList,
  getPaymentMethodsList,
  getBankAccountCcidDetail,
  addEditBankReceiptMethod,
  addEditPaymentMethod,
  getCustomerIndirectTax,
  getCustomerSiteByPartyId,
  getCustomerBankByPartyId,
  getCustomerSiteContactByPartyId,
  getCustomerSiteAccountByPartyId,
  getCustomerDirectTaxByPartyId,
  setUnsetPartyType,
  setUnsetPartyShortName,
  getCustomerSiteById,
  getPartyVenderSiteById,
  getCustomerSiteByOu,
  getVendorSiteByOu,
  getCustomerHistoryByCustomerId,
  getVendorHistorybyVendorId,
  getPartyHistorybyPartyId,
  uploadExcelParty,
  getActive,
  updateActive,
  postVendorOnboardExcel,
  getVendorUploadExcelData,
  getCustomerContact,
  getAddEditCustomerSiteContactNew

};
